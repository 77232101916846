@import '../../../styles/variables.scss';
@import '../../../styles/mixin.scss';

.modal_wrap {
  padding: 30px 40px;

  .modal_header_area {
    float: right;
    padding: 0 0 20px 0;

    p {
      font-size: 12px;
      margin:10px 0 0 0;
    }

    .search_area {
      @include align_items(center);
      .search_form {
        margin-right: 10px;
      }

      .reset_button {
        height: 36px;
        width: 55px;
        font-size: 12px;
        background-color: $white;
        line-height: 0;
      }
    }
  }

  .table_area {
    overflow: auto;
    width: 622px;
    max-height: 390px;
    min-height: 350px;
    margin-bottom: 40px;

    .checkbox_div {
      border-radius: 2px;
      width: 18px;
      height: 18px;
      margin: auto;
      padding-top: 4px;
    }
  }

  .paging_area {
    border: 1px solid red;
    // height: 50px;
  }

  .button_area {
    @include flex_direction(row);
    justify-content: flex-end;
    padding-bottom: 30px;

    .cancel_button {
      width: 74px;
      height: 42px;
      background-color: $white;
      font-size: 15px;
    }
    .select_button {
      width: 108px;
      height: 42px;
      font-size: 15px;
    }
  }

  button:first-child {
    margin-right: 10px;
  }
}


.active_checkbox {
  background-color: $sub-color;
  border: none;
}

.inactive_checkbox {
  border: solid 1px $gray-3;
  background-color: $white;
}