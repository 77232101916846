@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.full_date_wrap {
  @include align_items(center);
  .period_date_picker {
    font-size: 13px;
  }

  .calc_result {
    background: #f8f8f8;
    font-size: 14px;
    color: $black;
    border-radius: 3px;
    padding: 3px 10px;
    margin-left: 5px;
  }
}

.time_group {
  @include align_items(center);
  margin-left: 5px;
  font-size: 13px;
  &>span {
    margin: 0 5px;
  }
  .select_time {
    width: 60px;
    text-align: center;
  }
  .minutes {
    font-size: 13px;
    width: 50px;
    text-align: center;
  }
}