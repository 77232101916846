@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.double_type_wrap {
  @include align_items(center);
  font-size: 14px;

  span {
    margin: 0 5px;
  }

  .add_text {
    font-size: 13px;
    margin-left: 5px;
  }
}
