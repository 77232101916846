@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.radio_type_wrap {
  .select {
    width: 150px;
  }
}

.wrap_flex {
  @include align_items(center);
  flex-wrap: wrap;
  width: 100%;
}

.describe {
  height: 24px;
  padding: 6px 10px 4px;
  border: 1px solid $black;
  border-radius: 12px;
  font-weight: normal;
  font-size: $small-font-size;
  color: $black;
}
