@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.stop_dragging {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.select_input {
  @include align_items(center);
  @include justify_content(space-between);
  font-size: 0.75em;
  color: #c5c0bc;
  height: 28px;
  border-radius: 3px;
  border: solid 1px #d7d7d7;
  padding: 0 10px;
  width: 250px;
  cursor: pointer;
  background-color: #ffffff;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input_value {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  position: absolute;
  margin-top: 5px;
  z-index: 1400;
  background-color: white;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid 1px #d7d7d7;
}

.list {
  max-height: 170px;
  overflow-y: auto;
  cursor: pointer;

  .ul_wrapper {
    @include align_items(center);
    height: 28px;
    &:hover {
      background: #82b7f5;
      color: white;
    }
  }
  ul {
    width: 100%;
    padding: 0 10px;
    margin: 0;
    line-height: initial;
    word-break: break-all;
    font-size: 0.75em;
    color: $main-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .selected {
    background: #3888e6;
    color: white;
  }
}
.list::-webkit-scrollbar {
  width: 8px;
}
.list::-webkit-scrollbar-thumb {
  background-color: #b2b2b2;
  border-radius: 5px;
}
.list::-webkit-scrollbar-track {
  background-color: white;
}

.input_wrap {
  padding: 0 7px 0 10px;
}

.search_input {
  width: 100%;
  padding: 0px !important;
  font-size: 0.75em;
  color: #282828;
  height: 28px;
  border: solid 3px #d7d7d7;
  border-right-width: 0px;
  border-left-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::placeholder {
    color: $gray-4;
  }
}

.list_no_data {
  font-size: 0.75em;
  line-height: initial;
  color: $main-color;
  padding: 14px 10px;
}
