@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.modal_wrap {
  padding: 30px 40px;

  .modal_header_area {
    float: right;
    padding: 0 0 20px 0;

    p {
      font-size: 12px;
      margin: 10px 0 0 0;
    }

    .search_area {
      @include align_items(center);
      .search_form {
        margin-right: 10px;
      }

      .reset_button {
        height: 36px;
        width: 55px;
        font-size: 12px;
        background-color: $white;
        line-height: 0;
      }
    }
  }

  .table_area {
    overflow: auto;
    width: 622px;
    max-height: 390px;
    min-height: 350px;
    margin-bottom: 40px;
  }

  .paging_area {
    border: 1px solid red;
    // height: 50px;
  }

  .button_area {
    @include flex_direction(row);
    justify-content: flex-end;
    padding-bottom: 30px;

    .cancel_button {
      width: 74px;
      height: 42px;
      background-color: $white;
      font-size: 15px;
    }
    .select_button {
      width: 108px;
      height: 42px;
      font-size: 15px;
    }
  }

  button:first-child {
    margin-right: 10px;
  }
}

.header {
  @include flex_direction(row);
  @include justify-content(space-between);
  margin-bottom: 20px;
  
  .change_warning {
    font-size: 13px;
    color: #000000;
    article {
      line-height: 19px;
    }
  }
  .option_wrap {
    @include flex_direction(row);
    .option_btn {
      padding: 10px 10px;
      @include center_mid();
      border-radius: 3px;
      background-color: #efefef;
      font-size: 14px;
      color: #575757;
      cursor: pointer;
      margin-right: 7px;
    }
    .option_btn:last-child {
      cursor: pointer;
      margin-right: 0px;
    }
    .active_option_btn {
      background-color: #575757;
      color: #ffffff;
    }
  }
  .search_input_wrap {
    @include flex_direction(row);
    .group_search_wrap {
      @include flex_direction(row);
      align-items: center;
      margin-right: 7px;
      span {
        width: 33px;
        line-height: 12px;
        font-size: 12px;
        margin-right: 10px;
      }
    }

    .search_input {
      margin-right: 12px;
    }
    .clear_btn {
      width: 100%;
    }
  }
}

.table {
  height: 288px;
  overflow-y: auto;
  th {
    height: 36px;
  }
  
}

._td {
  height: 36px;
  font-size: 12px;
  word-break: break-all;
  .recommend {
    @include center_mid();
    font-size: 11px;
    width: 36px;
    height: 20px;
    border-radius: 5px;
    background-color: #ffdc4a;
  }
}

.checkBox_wrap {
  @include center_mid();
  .active_checkbox {
    @include center_mid();
    width: 20px;
    height: 20px;
    background-color: $sub-color;
    border-radius: 3px;
    border: none;
  }

  .inactive_checkbox {
    @include center_mid();
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: solid 1px $gray-3;
    background-color: $white;
  }
}

.footer {
  margin-top: 40px;
  @include flex_direction(row);
  @include justify-content(space-between);
  @include align_items(center);
  .desc {
    font-size: 13px;
  }
  .cancel_button {
    height: 42px;
    padding: 12px 23px 12px 24px;
    border-radius: 3px;
    border: solid 1px #575757;
    margin-right: 10px;
    background-color: #ffff;
  }
  .select_button {
    height: 42px;
    padding: 12px 25px 12px 26px;
    border-radius: 3px;
  }
}
