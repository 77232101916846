@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.tooltip {
  flex-direction: row;
  position: relative;
  max-height: 14px;
}

.tooltip_trigger {
  display: inline-block;
  padding: 0;
}

.tooltip_bubble {
  min-width: 60px;
  position: absolute;
  z-index: 10;
  top: 30px;

  &::after {
    content: "";
    position: absolute;
  }
}

.tooltip_top {
  top: -35px;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid $white;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip_bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);
}

.tooltip_left {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  &::after {
    border-left: 9px solid $white;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 49%;
    right: -7px;
    transform: translateY(-50%);
  }
}

.tooltip_right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);
  &::after {
    border-right: 9px solid $white;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.tooltip_message {
  white-space: pre;
  background: $white;
  border-radius: 3px;
  color: $new-gray-7;
  line-height: 1.4;
  padding: 5px;
  text-align: center;
  border: 1px solid $new-gray-4;
  // font-size: $small-font-size;
}

.triangle {
  display: block;
  width: 0;
  height: 1px;
  position: absolute;
}

.triangle_bottom {
  border-top: 10px solid none;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $new-gray-4;;
  border-left: 10px solid transparent;
  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0px;
    border-top: 10px solid none;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $white;
    border-left: 10px solid transparent;
    position: absolute;
    top: 2px;
    left: -10px;
  }
}

.triangle_left {
  border-top: 10px solid transparent;
  border-right: 10px solid none;
  border-bottom: 10px solid transparent;
  border-left: 10px solid $new-gray-4;;
  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0px;
    border-top: 10px solid none;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $white;
    border-left: 10px solid transparent;
    position: absolute;
    top: 2px;
    left: -10px;
  }
}

.triangle_top {
  border-bottom: 10px solid none;
  border-right: 10px solid transparent;
  border-top: 10px solid $new-gray-4;;
  border-left: 10px solid transparent;
  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0px;
    border-top: 10px solid none;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    top: 1px;
  }
}
