@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

.input {
  width: 100%;
  height: 28px;
  padding: 0 5px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  color: $main-color;
  &:disabled {
    background: #f1f1f1;
  }
  &::placeholder {
    color: $gray-4;
  }
  font-size: 14px;
}

.clear_button_address {
  position: absolute;
  padding: 5px;
  right: 110px;
  object-fit: contain;
  cursor: pointer;
}

.clear_button {
  position: absolute;
  margin-left: 155px;
  padding: 5px;
  object-fit: contain;
  cursor: pointer;
}