@import '../../../styles/mixin.scss';
@import '../../../styles/variables.scss';

.apply_partner_container {
  margin-top: 50px;
  padding-bottom: 80px;
  .header_area {
    @include flex_direction(row);
    @include justify_content(center);
    min-width: 1080px;
    border-bottom: 1px solid #e6e6e6;
    padding: 35px 0;
    .header_title {
      font-size: 28px;
      font-weight: bold;
      margin-right: 626px;
    }
    .header_step_wrap {
      @include flex_direction(row);
      padding-top: 8px;
      float: right;
      div {
        font-size: 14px;
        color: #bebdbd;
        margin-right: 10px;
      }
      .current_step {
        color: $sub-color;
        font-weight: bold;
      }
    }
  }
  .apply_partner_content_wrap {
    @include justify_content(center);
    min-width: 1080px;
    padding: 10px 10px 0 0;
  }
}

.apply_partner_introduce_container {
  @include flex_direction(column);
  @include align_items(center);
  margin-top: 60px;
  .title {
    display: inline-block;
    font-size: 28px;
    border-bottom: 2px solid rgba(255, 167, 35, 0.16);
    box-shadow: inset 0 -10px rgba(255, 167, 35, 0.16);
    margin-bottom: 40px;
  }
  .content {
    font-size: 16px;
    color: $main-color;
    margin-bottom: 5px;
  }
  .process_img {
    width: 1000px;
    height: 330px;
    margin-top: 35px;
    margin-bottom: 100px;
  }
  .location_table_img {
    width: 1000px;
    height: 215px;
    margin-top: 40px;
    margin-bottom: 80px;
  }
  .button_wrap {
    @include flex_direction(row);
    @include justify_content(space-between);
    width: 320px;
    .button {
      width: 150px;
      height: 50px;
      padding-top: 15px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
    }
    :first-child {
      border: 1px solid $black;
    }
    :last-child {
      background-color: $black;
      color: $white;
    }
  }
}

.apply_partner_input_container {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1080px;
  .apply_process_wrap {
    @include flex_direction(row);
    margin-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #e6e6e6;
    .title {
      font-size: 20px;
      width: 275px;
      line-height: 22px;
      span {
        color: $sub-color;
      }
    }
    .content_wrap {
      .content_title {
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 30px;
      }
      .content {
        @include flex_direction(row);
        color: #424242;
        max-width: 730px;
        word-break: keep-all;
        line-height: 28px;
        .dot {
          width: 4px;
          height: 4px;
          border-radius: 10px;
          background-color: $black;
          margin-top: 10px;
          margin-right: 8px;
        }
        span {
          font-weight: bold;
        }
        .dot {
          font-size: 30px;
        }
      }
      .content_margin_bottom {
        margin-bottom: 8px;
      }
      :first-child {
        margin-top: 0;
      }
      :last-child {
        margin-bottom: 0;
      }
      .necessity_document_table {
        @include flex_direction(row);
        @include justify_content(space-between);
        border-top: 1px solid $black;
        border-bottom: 1px solid #e6e6e6;
        min-width: 804px;
        height: 65px;
        .necessity_document_table_cell {
          @include flex_direction(row);
          @include justify_content(space-between);
          width: 50%;
          padding: 15px;
          .text {
            padding-top: 9px;
          }
        }
        .right_border {
          border-right: 1px solid #e6e6e6;
        }
        .necessity_document_table_button {
          width: 100px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          color: $sub-color;
          border: 1px solid $sub-color;
          font-size: 14px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      .complete_write_plan_wrap {
        @include flex_direction(row);
        margin-top: 33px;
        .checkbox_div {
          width: 24px;
          height: 24px;
          border-radius: 3px;
          border: solid 1px #959595;
          margin-right: 12px;
          cursor: pointer;
        }
        .text {
          line-height: 26px;
          cursor: pointer;
        }
        img {
          margin: 6px 0 0 5px;
          width: 13px;
          height: 12px;
        }
      }
    }
  }
}

.agree_policy_wrap {
  @include flex_direction(row);
  @include justify_content(center);
  margin-top: 54px;
  .checkbox_div {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    border: solid 1px #959595;
    margin-right: 12px;
  }
  .text {
    line-height: 26px;
    cursor: pointer;
  }
  img {
    margin: 6px 0 0 5px;
    width: 13px;
    height: 12px;
  }
}

.apply_partner_button_wrap {
  @include justify_content(center);
  margin-top: 50px;
  .apply_partner_button {
    display: inline-block;
    width: 180px;
    height: 52px;
    line-height: 52px;
    background-color: #bebdbd;
    border-radius: 3px;
    color: $white;
    text-align: center;
    cursor: pointer;
  }
}

.tooltip_wrap {
  @include flex_direction(row);
  height: 38px;
  margin-top: 5px;
  .tooltip_triangle {
    width: 0;
    height: 0;
    margin-top: 9px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 14px solid #bebdbd;
  }
  .tooltip_triangle_blank {
    position: relative;
    width: 0;
    height: 0;
    left: 16px;
    margin-top: 9px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 14px solid $white;
    z-index: 10;
  }
  .tooltip_content {
    padding: 10px;
    border: 1px solid #bebdbd;
    border-radius: 5px;
    font-size: 14px;
  }
}

.evaluating_apply_partner_modal_container {
  @include flex_direction(column);
  @include align_items(center);
  padding: 40px 0 26px 0;
  .content {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .sub_content {
    font-size: 14px;
    color: #959595;
  }
  .button_wrap {
    @include flex_direction(row);
    margin-top: 35px;
    margin-bottom: 30px;
    .button {
      width: 170px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      color: $white;
      border-radius: 5px;
      cursor: pointer;
    }
    :first-child {
      background-color: $black;
      margin-right: 15px;
    }
    :nth-child(2) {
      background-color: $sub-color;
    }
  }
}

.cant_apply_partner_modal_container {
  @include flex_direction(column);
  @include align_items(center);
  padding: 20px 0 40px;
  .content {
    font-size: 20px;
    margin-bottom: 22px;
  }
  .sub_content {
    font-size: 14px;
    color: #959595;
    margin-bottom: 5px;
  }
}