@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.radio_wrap {
  @include align_items(center);
    
  input {
    margin: 0;
    padding: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }
  label {
    @include align_items(center);
    position: relative;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
    &::before {
      content: "";
      display: block;
      min-width: 15px;
      min-height: 15px;
      border: 1px solid #bebdbd;
      border-radius: 50%;
      margin-right: 5px;
      cursor: pointer;
    }
  }

  input:checked + label {
    &::before {
      position: relative;
      border: 1px solid #fb9310;
    }
    &::after {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      background: #fb9310;
      border-radius: 50%;
      position: absolute;
      left: 3px;
      bottom: 3px;
    }
  }

  input:disabled + label {
    &::before {
      position: relative;
      border: 1px solid $gray-4;
      background: $gray-4;
    }
    &::after {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      background: none;
      border-radius: 50%;
      position: absolute;
      left: 3px;
      bottom: 3px;
    }
  }
}

.dividedGroup1_wrap{
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 11px;
}
.dividedGroup2_wrap {
  width: 98%;
  border-top: 1px dashed black;
  padding-top: 8px;
}
.dividend_wrap{
  margin: 0
}