@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.wrap {
  padding: 10px 20px 12px;

  .title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin: 0px;
  }

  .sign_canvas_area {
    border: 1px solid #929292;
    border-radius: 10px;
    height: 258px;
    margin-top: 20px;
  }

  .button_container {
    margin-top: 32px;
    display: flex;
    height: 56px;

    .sign_reset_button {
      flex: 1.5;
      background-color: #fff;
      border-radius: 10px;
      height: 100%;
      font-size: 16px;
    }
    .sign_sumit_button {
      flex: 3;
      border-radius: 10px;
      height: 100%;
      margin-left: 15px;
      font-size: 16px;
    }

    .button_active {
      background-color: #282828;
    }

    .button_inactive {
      background-color: #d7d7d7;
    }
  }
}
