
.normal_car_change_info {
  padding: 30px 40px;
  p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.5;
  }
  .btn_area {
    margin-top: 50px;
    text-align: right;
    .cancel_button {
      background-color: white;
    }
    button {
      width: 64px;
      height: 45px;
      font-size: 15px;
      &:last-child {
        margin-left: 15px;
        width: 108px;
      }
    }
  }
}