@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  @include flex_direction(row);
  @include justify_content(center);
  @include align_items(center);
  width: 100%;
  height: calc(100vh - 96px);
  padding: 25px;

  .content_wrap {
    @include flex_direction(column);
    // @include flex_wrap(wrap);
    @include justify_content(center);
    @include align_items(center);  
    p {
      margin: 0;
    }
  }

  img {
    width: 140;
    height: 120px;
  }

  .right_block {
    @include flex_direction(column);
    @include justify_content(center);

    .content_text_title {
      text-align: center;
      font-size: 26px;
      font-weight: $font-weight-regular;
      margin-top: 20px;
    }
    .content_text {
      text-align: center;
      color: #929292;
      font-size: 17px;
      font-weight: $font-weight-regular;
      margin-top: 25px;
    }
    .back_btn {
      width: 130px;
      height: 42px;
      margin: 0 auto;
      margin-top: 40px;
      font-size: 15px;
      background: white;
      color: #575757;
      border: 1px solid #d7d7d7;
      border-radius: 5px;
    }
  }
}