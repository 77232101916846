@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.address_wrap {
  @include align_items(center);
  width: 100%;
  height: 100%;

  .address_btn {
    min-width: 90px;
    margin-left: 10px;
  }
}

.address_detail {
  @include flex_direction(column);
  height: 500px;
  padding: 30px 25px 0 25px;

  .form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .research {
    @include align_items(center);
    min-height: 44px;
    margin-bottom: 10px;
    input {
      height: 100%;
      font-size: 14px;
    }
    button {
      height: 100%;
    }
  }
  .detail_address {
    height: 44px;
  }
  .address_btn {
    min-width: 90px;
    margin-left: 10px;
  }

  .btn_area {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: 20px;
    button {
      width: 100px;
      height: 40px;
    }
    button:first-child {
      margin-right: 10px;
      background-color: $white;
    }
  }
}

.mobile_address_btn {
  width: 100%;
  height: 44px;
}

.mobile_address_search {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}
