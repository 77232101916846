@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.contents_wrap {
  position: relative;
  overflow: auto;
  min-width: 1100px;
  background: $white;
  min-height: 91vh;
  margin-left: 200px;
  padding-top: 50px;
  .page_header {
    // background: $main-color;
    // color: $white;
  }
  .page_header_title {
    min-width: 100px;
    height: auto;
    display: block;
    font-weight: $font-weight-medium;
    font-size: 28px;
    margin: 10px 46px 10px 0;
    color: $black;
    font-size: 18px;
    font-weight: bold;
  }
  .contents_wrap_contents {
    padding: 8px 30px;
    height: 100%;
    text-align: left;
    margin-bottom: 40px;
    // min-width: 1300px;
  }
  .penalty_notice_contents {
    @include flex_direction(row);
    margin: 15px 0 7px 30px;
    width: 680px;
    height: 45px;
    border-radius: 5px;
    background-color: $sub-color;
    color: $white;
    img {
      margin: 10px;
      width: 26px;
      height: 26px;
    }
    p {
      margin: auto 0;
      height: 18px;
    }
  }
}

.modal_contents_wrap {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 700px;
  // min-width: 1100px;
  background: $white;
  // min-height: 100vh;
  // margin-left: 200px;
  // padding-top: 50px;
  .page_header {
    // background: $main-color;
    // color: $white;
  }
  .page_header_title {
    min-width: 100px;
    height: auto;
    display: block;
    font-weight: $font-weight-medium;
    font-size: 28px;
    margin: 10px 46px 10px 0;
    color: $black;
    font-size: 18px;
    font-weight: bold;
  }
  .contents_wrap_contents {
    padding: 8px 30px;
    height: 100%;
    text-align: left;
    margin-bottom: 40px;
    // overflow: auto;
  }
  .penalty_notice_contents {
    @include flex_direction(row);
    margin: 15px 0 7px 30px;
    width: 680px;
    height: 45px;
    border-radius: 5px;
    background-color: $sub-color;
    color: $white;
    img {
      margin: 10px;
      width: 26px;
      height: 26px;
    }
    p {
      margin: auto 0;
    }
  }
}

.modal_contents_wrap {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 700px;
  // min-width: 1100px;
  background: $white;
  // min-height: 100vh;
  // margin-left: 200px;
  // padding-top: 50px;
  .page_header {
    // background: $main-color;
    // color: $white;
  }
  .page_header_title {
    min-width: 100px;
    height: auto;
    display: block;
    font-weight: $font-weight-medium;
    font-size: 28px;
    margin: 10px 46px 10px 0;
    color: $black;
    font-size: 18px;
    font-weight: bold;
  }
  .contents_wrap_contents {
    padding: 8px 30px;
    height: 100%;
    text-align: left;
    margin-bottom: 40px;
    // overflow: auto;
  }
  .penalty_notice_contents {
    @include flex_direction(row);
    margin: 15px 0 7px 30px;
    width: 680px;
    height: 45px;
    border-radius: 5px;
    background-color: $sub-color;
    color: $white;
    img {
      margin: 10px;
      width: 26px;
      height: 26px;
    }
    p {
      margin: auto 0;
    }
  }
}

.modal_contents_wrap {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 700px;
  // min-width: 1100px;
  background: $white;
  // min-height: 100vh;
  // margin-left: 200px;
  // padding-top: 50px;
  .page_header {
    // background: $main-color;
    // color: $white;
  }
  .page_header_title {
    min-width: 100px;
    height: auto;
    display: block;
    font-weight: $font-weight-medium;
    font-size: 28px;
    margin: 10px 46px 10px 0;
    color: $black;
    font-size: 18px;
    font-weight: bold;
  }
  .contents_wrap_contents {
    padding: 8px 30px;
    height: 100%;
    text-align: left;
    margin-bottom: 40px;
    // overflow: auto;
  }
  .penalty_notice_contents {
    @include flex_direction(row);
    margin: 15px 0 7px 30px;
    width: 680px;
    height: 45px;
    border-radius: 5px;
    background-color: $sub-color;
    color: $white;
    img {
      margin: 10px;
      width: 26px;
      height: 26px;
    }
    p {
      margin: auto 0;
    }
  }
}
