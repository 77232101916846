@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.table_scroll_style {
  height: 100%;
  thead {
    th {
      font-size: 12px;
      font-weight: 400;
      background-color: $gray-5;
      border-top: 1px solid $gray-3;
      border-left: none;
      border-right: none;
    }
  }

  tbody {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    th,
    td {
      font-size: 12px;
      font-weight: 400;
      border-left: none;
      border-right: none;
    }
    td {
      border: none;
    }
    tr {
      @include align_items(center);
      border-bottom: 1px solid $gray-3;
    }
  }

  tbody::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  tbody {
    display: block;
    max-height: 600px;
    overflow-y: scroll;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.tooltipImg {
  width: 14px;
  height: 14px;
  margin: 5px 5px 0 5px;
}

.overlay {
  @include center_mid();
}
