@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

$select-height: 28px;

.select_wrap {
  @include align_items(center);
  width: 100%;
  height: $select-height;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  background: white;

  .arrow_area {
    margin-left: auto;
    // transform: rotate(90deg);
  }

  .clear_area {
    @include align_items(center);
    width: 10px;
    height: 10px;
    img {
      width: 100%;
      display: block;
    }
  }

  .view_selected {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: initial;
    width: 90%;
    overflow: hidden;
    text-align: left;
  }

  .list_area {
    position: absolute;
    top: $select-height + 5px;
    left: 0;
    z-index: 10;
    border: 1px solid #dddddd;
    border-radius: 3px;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    background: white;
    font-size: 13px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      padding: 7px 10px;      
      line-height: initial;
      word-break: break-all;
      &:hover {
        background: #82b7f5;
        color: white;
      }
    }
    .empty_text {
      color: $gray-4;
    }
    .selected_on {
      background:#3888e6;
      color: white;
    }
  }

  .up {
    top: inherit;
    bottom: $select-height + 5px;
  }

}

.placeholder {
  color: $gray-3 !important;
}

.necessary_icon {
  position: absolute;
  left: -15px;
  top: 50%;
  img {
    width: 7px;
    height: 7px;
  }
}