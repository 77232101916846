@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

.container {
  @include flex_direction(row);
  @include justify_content(flex-start);
  @include align_items(center);

  .check {
    cursor: pointer;
    background-color: $white;
    border: 1px solid $gray-3;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 3px;

    img {
      width: 11px;
      height: 10px;
    }

    &[data-active=true] {
      background-color: $sub-color;
      border-color: transparent;
    }
  }

  .disabled {
    background-color: #d7d7d7;
    border: 1px solid #d7d7d7;
    cursor: not-allowed;
  }

  .text {
    font-size: 13px;
    line-height: 15px;
    margin-left: 10px;

    p {
      margin: 0;
    }
  }
}