@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.btn_border {
  border-radius: 10px;
}

.file_input_btn_area {
  @include align_items(center);
  margin-top: 20px;
}

.table_file_input_area {
  @include align_items(center);
  width: 100%;
  button {
    border: none;
    background: $gray-2;
    color: white;
    border-radius: 3px;
    margin-left: auto;
    min-width: 76px !important;
    max-width: 76px !important;
    height: 28px !important;
    font-size: 13px !important;
    margin-right: 12px;
  }
}

.thumbnail_upload {
  input {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    display: none;
  }
  label {
    @include align_items(center);
    @include justify_content(center);
    @include flex_direction(column);
    background: $gray-6;
    width: 84px;
    height: 84px;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    p {
      margin: 0;
      font-size: 11px;
      color: #929292;
    }
    p:first-child {
      margin-top: -7px;
      width: 24px;
      height: 24px;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}