@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.text {
  font-size: 16px;
  line-height: 1.5;
}

.text_area {
  margin-top: 15px;
  font-size: 13px;
  line-height: 1.15;
}
