@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.text {
  line-height: 23px;

  .first_notice {
    font-weight: $font-weight-bold;
    padding-bottom: 35px;
  }

  .article_wrap {
    @include flex_direction(row);
    @include align_items(flex-start);
    padding-bottom: 30px;
  }
}

.warning_box {
  @include flex_direction(row);
  img {
    width: 20px;
    height: 26px;
    margin-right: 8px;
  }
  border-radius: 8px;
  padding: 15px;
  color: $red;
  font-size: 15px;
  line-height: 24px;
  background-color: #f9f9f9;
}