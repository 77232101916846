@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.input_type_wrap {
  @include align_items(center);
  font-size: 14px;
  width: 100%;
  .input {
    width: 150px;
    margin-right: 5px;
    font-size: 14px;
  }

  .add_text {
    font-size: 13px;
    margin-left: 5px;
  }
  .add_small_text {
    font-size: 12px;
    margin-left: 5px;
  }
}

.alimtalk_mark_warp {
  @include center_mid();
  background-color: #ffeb00;
  border-radius: 12px;
  font-size: 11px;
  padding: 5px 10px;
  margin-left: 10px;
  .alimtalk_img {
    width: 13px;
    height: 13px;
    margin-right: 3px;
  }
  .alimtalk_text {
    margin-top: 3px;
  }
}