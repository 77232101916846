@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";


.output_area {
  @include align_items(center);
  @include justify_content(space-between);
  width: 100%;
  .add_text {
    border: 1px solid #575757;
    padding: 5px 10px 3px;
    border-radius: 10px;
    margin-left: 20px;
    font-size: 12px;
    color: #575757;
  }

  .outpu_btn {
    margin-left: auto;
    margin-right: 10px;
  }
}

.alimtalk_mark_warp {
  @include center_mid();
  background-color: #ffeb00;
  border-radius: 12px;
  font-size: 11px;
  padding: 5px 10px;
  margin-left: 10px;
  .alimtalk_img {
    width: 13px;
    height: 13px;
    margin-right: 3px;
  }
  .alimtalk_text {
    margin-top: 3px;
  }
}

.grade_good {
  color: #007de8;
}

.grade_bad {
  color: #e05656;
}
