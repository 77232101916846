@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.info_text {
  display: flex;
  font-size: 18px;
  font-weight: bold;
}

.reconfirm_text {
  margin-top: 16px;
  font-size: 18px;
  line-height: 1.44;
}

.self_contract_guide {
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 14px;
  text-align: center;
}
