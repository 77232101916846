@import '../../../styles/variables.scss';
@import '../../../styles/mixin.scss';

.sales_employee_modal_wrap {
  min-width: 700px;
  padding: 40px 30px;
  .search_input_wrap {
    @include justify-content(flex-end);
  }
  .table_area {
    margin-top: 21px;
    margin-bottom: 10px;
    height: 436px;
    overflow-y: auto;
  }
}

.reset_button {
  height: 36px;
  width: 55px;
  font-size: 12px;
  background-color: $white;
  margin-left: 10px;
  line-height: 0;
}

.checkbox_div {
  border-radius: 2px;
  width: 18px;
  height: 18px;
  margin: auto;
  padding-top: 4px;
}

.active_checkbox {
  background-color: $sub-color;
  border: none;
}

.inactive_checkbox {
  border: solid 1px $gray-3;
  background-color: $white;
}

.button_area {
  @include flex_direction(row);
  @include justify_content(flex-end);
  margin-top: 20px;
  .cancel_button {
    @include center_mid();
    width: 74px;
    height: 42px;
    border-radius: 3px;
    border: solid 1px $gray-2;
    margin-right: 15px;
    cursor: pointer;
  }
  .complete_button {
    @include center_mid();
    width: 74px;
    height: 42px;
    border-radius: 3px;
    background-color: $gray-2;
    color: $white;
    cursor: pointer;
  }
}

.td_none_attachment {
  height: 120px;
  border: none;
}