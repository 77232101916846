@import '../../../styles/variables.scss';
@import '../../../styles/mixin.scss';

.partner_modal_wrap {
  min-width: 700px;
  padding: 30px 40px;
  .table_area {
    margin-top: 21px;
    margin-bottom: 10px;
    height: 436px;
    overflow-y: auto;
  }
}

.search_input_wrap {
  @include align_items(center);
  @include justify_content(flex-end);
}

.reset_button {
  height: 36px;
  width: 55px;
  font-size: 12px;
  background-color: $white;
  margin-left: 10px;
  line-height: 0;
}

.checkbox_div {
  border-radius: 2px;
  width: 18px;
  height: 18px;
  margin: auto;
  padding-top: 4px;
}

.active_checkbox {
  background-color: $sub-color;
  border: none;
}

.inactive_checkbox {
  border: solid 1px $gray-3;
  background-color: $white;
}

.footer {
  @include flex_direction(row);
  @include justify_content(space-between);
  margin-top: 20px;
  .button_wrap {
    @include flex_direction(row);
    @include align-items(flex-end);
    .cancel_button {
      @include center_mid();
      width: 74px;
      height: 42px;
      border-radius: 3px;
      border: solid 1px $gray-2;
      margin-right: 15px;
      cursor: pointer;
    }
    .complete_button {
      @include center_mid();
      width: 74px;
      height: 42px;
      border-radius: 3px;
      background-color: $gray-2;
      color: $white;
      cursor: pointer;
    }
  }
}

.alimtalk_wrap {
  @include flex_direction(column);
  padding: 12px 20px;
  border-radius: 7px;
  background-color: $gray-7;
  flex: 1; 
  margin-right: 15px;
  cursor: pointer;
  .alimtalk_notice_title_wrap {
    @include flex_direction(row);
    @include justify_content(flex-start);
    .alimtalk_checkbox_div {
      border-radius: 2px;
      width: 18px;
      height: 18px;
      padding: 2px;
      .alimtalk_check_img {
        padding-top: 0;
      }
    }
    .alimtalk_label {
      margin: 0 10px;
      font-size: 13px;
      line-height: 18px;
    }
  }
  .cant_alimtalk_label {
    font-size: 13px;
    line-height: 18px;
  }
  .alimtalk_notice_text {
    font-size: 12px;
    color: $gray-2;
    line-height: 18px;
    margin-bottom: 0;
  }
}
