@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

 .search_input_btn_area {
  @include align_items(center);
  position: relative;
  border: 1px solid $old-gray-5;
  width: 280px;
  height: 36px;
  overflow: hidden;
  input {
    max-width: 230px;
    border:none;
    height: 36px;
    padding: 10px;
  }
  input::placeholder {
    font-size: 13px;
  }
  button {
    @include align_items(center);
    @include justify_content(center);
    background: none;
    border:none;
    width: 40px;
    height: 36px;
    background-color: $old-gray-1;
    margin-left: auto;
    border-left: 1px solid $old-gray-5;
  }
}