@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixin.scss";

.return_request_modal_wrap {
  @include flex-direction(column);
  padding: 30px 40px;
  @include justify-content(space-between);
  .return_request_box_header {
    @include justify-content(flex-start);                                                                                                                                       
    @include align_items(center);
    margin: 10px 0px;
    font-weight: bold;
    .desc {
      margin-left: 10px;
      text-align: center;
      font-size: 13px;
      font-weight: normal;
      color: #bebdbd;
    }
  }
  .return_request_box {
    @include flex-direction(column);
    padding: 20px;
    border-radius: 3px;
    border: solid 1px #e6e6e6;
    margin-bottom: 20px;
    .return_request_row {
      color: $gray-2;
      @include flex-direction(row);
      margin: 4px 0px;
      font-size: 14px;
    }
  }
  .return_request_customer_call {
    @include justify_content(space-between);
    @include align_items(center);
    padding: 12px 15px 11px;
    border-radius: 3px;
    background-color: #f5f5f5;
    font-size: 15px;
    color: #282828;
    cursor: pointer;
    margin-bottom: 10px;
    .check_img {
      @include center_mid();
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: $gray-4;
    }
    .check_img_active {
      @include center_mid();
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: $gray-2;
    }
  }
  .return_request_memo {
    position: relative;
    margin-bottom: 40px;
    .memo_input {
      height: 46px;
      font-size: 14px;
    }
  }

  .button_area {
    @include justify_content(flex-end);
    @include align_items(center);
    .cancel_btn {
      cursor: pointer;
      height: 42px;
      padding: 12px 25px;
      border-radius: 3px;
      border: solid 1px $gray-2;
      margin-right: 10px;
      font-size:15px;
    }
    .save_btn {
      cursor: pointer;
      height: 42px;
      padding: 12px 40px;
      border-radius: 3px;
      background-color: $gray-2;
      color: $white;
      font-size:15px;
    }
  }
}
