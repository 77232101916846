@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.container {
  .close {
    position: absolute;
    top: -30px;
    right: 0px;
    width: 22px;
    height: 22px;
  }
  width: 620px;
  padding: 0px 60px;
  .header {
    padding: 20px;
    font-size: $xlarge-font-size;
    @include justify_content(center);
    @include align-items(center);
    @include flex-direction(column);
    .total {
      color: $sub-color;
    }
  }
  .body {
    max-height: 200px;
    overflow: auto;
  }
  .desc {
    margin-top: 10px;
    @include justify_content(flex-end);
    @include align-items(center);
    font-size: $small-font-size;
  }
  .is_group_contract {
    color: $blue;
  }
  .footer {
    color: $black;
    padding: 20px;
    @include justify_content(center);
    @include align-items(center);
    @include flex-direction(column);
  }
  .footer_button_wrap {
    @include justify_content(flex-end);
    margin-bottom: 20px;
    .footer_button {
      @include align-items(center);
      @include justify_content(center);
      width: 100px;
      height: 42px;
      background-color: $gray-2;
      color: $white;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}
