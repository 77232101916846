@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.modalWrap {
  padding: 30px 40px;

  .confirmText {
    font-size: 16px;
    line-height: 1.44;
  }

  .info_text {
    display: flex;
    font-size: 18px;
    font-weight: bold;
  }

  .input {
    display: flex;
    flex: 2.6;
    padding: 14px 12px 13px;
    font-size: 13px;
    height: auto;
  }

  .confirm_button {
    @include center_mid();
    display: flex;
    flex: 1;
    font-size: 15px;
    margin-left: 15px;
    padding: 12px 20px;
    color: $white;
    border: 1px solid $gray-2;
    border-radius: 3px;
    font-weight: normal;
    height: auto;
  }

  .input_container {
    display: flex;
    margin-top: 12px;
  }

  .caution {
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 14px;
    color: #000000;
  }

  .input_label {
    font-size: 16px;
    margin-top: 40px;
  }
}
