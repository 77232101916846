@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.schedule {
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: bold;
  span {
    margin-right: 18px;
  }
}

.schedule_txt {
  margin-top: 30px;
  p {
    line-height: 10px;
  }
}

.precautions {
  margin-top: 50px;
  p {
    font-size: 16px;
    line-height: 10px;
    &:first-child {
      margin-bottom: 22px;
    }
  }
}