@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.common_date_input {
  height: 100%;
}

.calendar_style {
  z-index: 10 !important;
}

.calendar_btn_area {
  @include align_items(center);
  width: 100%;
  height: 100%;
}

.calendar_btn_area + button {
  right: 35px !important;
  top: 15%;
  &:after {
    background: none;
    border: none;
    color: $gray-3;
    font-size: 20px;
    z-index: 11;
  }
}

.date_picker_wrap {
  position: relative;
  margin-bottom: -2px;
  .calendar_icon {
    width: 24px;
    height: auto;
    margin-left: 5px;
    cursor: pointer;
  }
  .disabled {
    cursor: not-allowed;
  }
  .cancel_icon {
    position: absolute;
    right: 37px;
    top: 50%;
    margin-top: -4px;
    width: 10px;
    height: auto;
    z-index: 11;
    cursor: pointer;
  }
}

.custom_input {
  position: relative;
  // z-index: 9;
  background: white;
  border: 1px solid #dddddd;
  border-radius: 3px;
  width: 110px;
  height: 30px;
  text-align: left;
  &:disabled {
    background: $gray-5;
    border: solid 1px $gray-4;
    color: $main-color;

    cursor: not-allowed;
  }
}

.calendar_header_wrap {
  @include align_items(center);
  @include justify_content(center);
  select {
    margin: 0 !important;
  }
  img {
    height: auto !important;
  }
  & > button {
    @include align_items(center);
    @include justify_content(center);
    height: 28px;
    margin: 0 5px;
  }
  .prev_month {
    img {
      transform: rotate(180deg);
    }
  }
}
