@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.container {
  @include center_mid();

  .content_wrap {
    @include flex_direction(column);
    @include align_items(center);
    margin: 40px 0 ;

    .car_number {
      margin: 0 0 10px 0;
      width: 100%;
      text-align: left;
      font-weight: bold;
    }
    
    .extend_time_info {
      @include justify_content(space-between);
      @include align_items(center);
      width: 440px;
      height: 92px;
      border-radius: 10px;
      padding: 0 25px;
      background-color: $gray-6;
      font-size: 18px;
      margin-bottom: 22px;

      .date_info_wrap {
        @include flex_direction(column);
        text-align: center;

        span {
          font-size: 18px;
          &:first-child {
            margin-bottom: 8px;
          }
        }
      }

      .request_extend_time {
        @include center_mid();
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;
        // padding: 10px 15px;
        width: 134px;
        height: 70px;
        border-radius: 5px;
        background-color: kakao(yellow-bold);
      }

      span:first-child {
        margin-bottom: 8px;
      }
    }

    .rent_extend_text {
      font-size: 14px;
      margin: 0;
      line-height: 1.57;
    }

    .confirm_rent_extend_button {
      width: 260px;
      height: 42px;
      margin: 22px auto 0;
      border-radius: 3px;
      font-size: 15px;
      outline: none;
      color: $white;
      background-color: $gray-2;
    }
  }
}