@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.container {
  @include flex_direction(column);
  @include justify_content(space-between);
  padding: 40px;
  .content {
    .content_header {
      @include align_items(center);
      @include flex_direction(row);
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: bold;
      .right_pillar {
        margin: 0px 5px;
        width: 2px;
        height: 19px;
        background-color: black;
      }
    }
    .content_body {
      .cancel_terminate_desc {
        @include flex_direction(column);
        line-height: 1.36;
        margin-top: -30px;
        font-size: 14px;
      }
    }
  }
  .footer {
    @include flex_direction(row);
    @include justify_content(flex-end);
    @include align_items(center);
    .cancel {
      cursor: pointer;
      @include center_mid();
      width: 74px;
      height: 42px;
      border-radius: 3px;
      border: solid 1px $gray-2;
      color: $gray-2;
      margin-right: 15px;
    }
    .submit {
      cursor: pointer;
      @include center_mid();
      width: 108px;
      height: 42px;
      border-radius: 3px;
      background-color: $gray-2;
      color: $white;
    }
  }
}
