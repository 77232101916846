@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.overlay {
  @include center_mid();
  @include flex_direction(column);
  .overlay_title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    color: $gray-2;
    margin-bottom: 20px;
  }
  .overlay_content {
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: normal;
    color: $gray-2;
  }
}

.outline_btn {
  width: 50px;
  @include center_mid();
  cursor: pointer;
  height: 28px;
  border-radius: 3px;
  border: solid 1px $gray-2;
  color: $gray-2;
  font-size: 13px;
}
.fill_btn {
  width: 50px;
  @include center_mid();
  cursor: pointer;
  height: 28px;
  border-radius: 3px;
  border: solid 1px $gray-2;
  background-color: $gray-2;
  color: #fff;
  font-size: 13px;
}

.remark {
  @include flex_direction(row);
  @include align_items(center);
  .close {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    // border-radius: 50%;
    // background-color: red;
  }
}

.due_date {
  border: solid 1px $gray-5;
  width: 100%;
  height: 28px;
  border-radius: 3px;
  text-align: center;
  // margin: 0 10px;
  // padding: 0 10px;
  font-size: 12px;
}

.number_input {
  width: 100%;
  border: solid 1px $gray-5;
  border-radius: 3px;
  height: 28px;
  text-align: center;
}
.unpaid {
  width: 100%;
  text-align: center;
  border-radius: 3px;
  height: 28px;
  border: solid 1px $red !important;
}
.active {
  width: 100%;
  text-align: center;
  border-radius: 3px;
  height: 28px;
  border: solid 1px $blue !important;
}
