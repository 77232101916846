@import "../../../../styles//mixin.scss";
@import "../../../../styles/variables.scss";

.header {
  @include flex();
  align-items: center;
  padding: 16px;
}

.title {
  @include justify_content(center);
  width: 100%;
  line-height: 1.67;
  font-size: 18px;
  font-weight: bold;
}
