@import '../../../styles/variables.scss';
@import '../../../styles/mixin.scss';

.search_car_info_modal_wrap {
  min-width: 520px;
  padding: 30px 40px;
  .car_name_wrap {
    @include flex_direction(column);
    @include center_mid();
    border-radius: 3px;
    background-color: $gray-6;
    height: 110px;
    margin-bottom: 31px;
    .car_name_label {
      font-size: 15px;
      color: $gray-2;
      margin-bottom: 15px;
    }
    .car_name {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .car_info_container {
    line-height: 1.5;
    padding-bottom: 22px;
    margin-bottom: 25px;
    border-bottom: 1px solid $gray-5;
    .car_info_wrap {
      @include flex_direction(row);
      @include justify-content(space-between);
      .car_info_label {
        color: $gray-2;
        font-size: 15px;
      }
      .car_info_content {
        color: $main-color;
        font-size: 16px;
      }
    }
  }
  .notice_text_wrap {
    font-size: 14px;
    line-height: 1.57;
    .notice_text {
      @include center_mid();
      span {
        color: red;
        margin: 0;
      }
    }
    :first-child {
      margin-bottom: 16px;
    }
  }
  .button_area {
    @include flex_direction(row);
    @include justify_content(flex-end);
    margin-top: 40px;
    .cancel_button {
      @include center_mid();
      width: 74px;
      height: 42px;
      border-radius: 3px;
      border: solid 1px $gray-2;
      margin-right: 15px;
      cursor: pointer;
    }
    .complete_button {
      @include center_mid();
      width: 108px;
      height: 42px;
      border-radius: 3px;
      background-color: $gray-2;
      color: $white;
      cursor: pointer;
    }
  }
}

// TODO 아래 삭제
.carInfoSearchModalTitleArea {
  height: 80px;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -webkit-flex;
}

.carInfoSearchModalContentArea {
  width: 70%;
  margin: 0 auto;
}

.carInfoSearchModalCarNameBox {
  border: 1px solid #d5d5d5;
  height: 90px;
  justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -ms-flex-direction: column;
}

.carInfoSearchModalButtonArea {
  margin-top: 20px;
}

.carInfoSearchModalButton {
  border: none;
  border-radius: 25px;
  height: 50px;
  color: $white;
  width: 170px;
  font-size: 16px;
  font-weight: 700;
}

.carInfoSearchModalButtonYes {
  float: right;
  background-color: $main-color;
}

.carInfoSearchModalButtonNo {
  float: left;
  background-color: #cecece;
}

.carInfoSearchModalButtonYes:hover, .carInfoSearchModalButtonNo:hover {
  background-color: $sub-color;
}