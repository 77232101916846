@import "../../styles/mixin.scss";

@mixin button {
  width: 130px;
  height: 42px;
  padding: 12px 25px;
  border-radius: 3px;
  border-width: 1px;
  background-color: white;
  color: #575757;
  font-size: 15px;
}

.wrapper {
  @include flex_direction(column);
  @include align-items(center);
  @include justify_content(center);
  height: 91vh;
  min-width: 1100px;

  .image {
    width: 140px;
    height: 119px;
  }

  .backButton {
    @include button();
  }

  .text {
    margin: 35px 0 55px;
    font-size: 26px;
    color: #282828;
  }

}

.already_confirm_policy_kakao_wrapper {
  @include flex_direction(column);
  @include align-items(center);
  @include justify_content(center);
  height: 91vh;
  min-width: 1100px;

  .image {
    width: 140px;
    height: 110px;
  }

  .text_main {
    line-height: 29px;
    font-size: 26px;
    font-weight: bold;
    margin: 30px 0 15px 0;
  }

  .text {
    font-size: 20px;
    line-height: 25px;
    color: #282828;
    margin: 0;
  }

  .mainButton {
    @include button();
    margin-top: 40px;
  }
}
