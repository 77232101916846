@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.cooking_register_modal_wrap {
  padding: 30px;

  .table_area {
    max-height: 300px;
    overflow: auto;
  }
}

.checkbox_div {
  border-radius: 2px;
  width: 18px;
  height: 18px;
  margin: auto;
  padding-top: 4px;
}

.active_checkbox {
  background-color: $sub-color;
  border: none;
}

.inactive_checkbox {
  border: solid 1px $gray-3;
  background-color: $white;
}

.search_input_wrap {
  @include align_items(center);
  @include justify_content(flex-end);
  margin-bottom: 30px;
}

.reset_button {
  height: 36px;
  width: 55px;
  font-size: 12px;
  background-color: $white;
  margin-left: 10px;
  line-height: 0;
}

.btn_area {
  @include justify_content(flex-end);
  width: 100%;
  margin-top: 100px;

  button {
    margin-left: 10px;
    width: 80px;
    height: 40px;
  }
}
