@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.select_type_wrap {
  @include align_items(center);

  .select_type {
    margin-right: 5px;
  }

  .add_small_text {
    font-size: 12px;
    margin-left: 5px;
  }
}

.payment_method {
  width: 150px;
  margin-left: 10px;
}