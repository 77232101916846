@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.modal_wrap {
  .content_wrap {
    padding: 30px 40px;
    .button_wrap {
      @include justify_content(flex-end);
      margin-top: 40px;
      .cancel {
        width: 110px; //고정
        height: 42px;
        padding: 0px 20px;
        background-color: $white;
        color: $gray-2;
        border: 1px solid $gray-2;
        border-radius: 3px;
        font-size: 15px;
      }
      .confirm {
        min-width: 94px;
        height: 42px;
        margin-left: 15px;
        padding: 0px 20px;
        color: $white;
        border-radius: 3px;
        font-size: 15px;
      }
    }
  }
}
