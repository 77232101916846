@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.insurance_input_wrap {
  @include align_items(center);
}

.single_input {
  // margin-left: 5px;
  width: 180px;
}

.double_input {
  @include align_items(center);
  .dash {
    margin: 5px;
  }
}