@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.page_header_wrap {
  position: relative;
  overflow: hidden;
}

.page_header_container {
  @include flex-direction(row);
  padding: 28px 30px 0px 30px;
  width: 100%;
  height: 100%;
  text-align: left;
}

.page_header_wrap_black {
  position: relative;
  overflow: hidden;
  min-width: 1100px;
  background: #2c2c2c;
  height: 100%;
  color: $white;
  padding: 18px 0;
  h1 {
    margin: 10px 0;
    margin-top: 4px;
    font-weight: 500;
  }
}

.page_header_container_black {
  margin: 0 auto;
  padding: 28px 30px 26px;
  width: 1080px;
  height: 100%;
  text-align: left;
}

.tab_container {
  display: flex;
  position: relative;
  flex-grow: 1;
  border-bottom: 1px solid $gray-3;
  height: 36px;
  .tab {
    display: block;
    min-width: 115px;
    padding: 0px 14px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    color: #c5c0bc;
  }
  .selected_tab {
    position: relative;
    margin-bottom: -1px;
    background-color: $white;
    border-right: 1px solid $gray-3;
    border-left: 1px solid $gray-3;
    border-top: 2px solid $sub-color;
    line-height: 32px;
    color: $sub-color;
  }
}

.tab_notification {
  margin: 0 0 1px 5px;
  padding: 3px 6px;
  border-radius: 4px;
  background-color: #ffa723;
  color: $white;
  font-size: 10px;
}
