@import "../../styles/variables.scss";
@import '../../styles/mixin.scss';

.toast_container {
  position: fixed;
  z-index: 1000000;
  right: 10px;
  width: 500px;
  height: auto;
  margin-top: 100px;

  div[data-type="text"] {
    padding: 24px 0;
    min-height: 64px;
    font-size: 16px;
    flex: 1;
    padding-left: 50px;
    white-space: pre-wrap;
    word-wrap: break-word;
    @include flex_direction(row);
    @include align_items(center);
    color: $black;
    font-size: $large-font-size;
    width: 100%;
    height: 100%;
  }

  div[data-type="success"] {
    background-color: #d2f0dc;
    background-image: url("/images/ic_success_24x24.png");
    background-repeat: no-repeat;
    background-position: 4%;
  }
  span[data-type="success"] {
    background-color: 'green';
  }
  div[data-type="warning"] {
    background-color: #ffefcf;
    background-image: url("/images/ic_warning_24x22.png");
    background-repeat: no-repeat;
    background-position: 4%;
  }
  span[data-type="warning"] {
    background-color: 'yellow';
  }
  div[data-type="error"] {
    background-color: #ffd2d1;
    background-image: url("/images/ic_error_24x24.png");
    background-repeat: no-repeat;
    background-position: 4%;
  }
  span[data-type="error"] {
    background-color: 'red';
  }
  div[data-type="info"] {
    background-color: #cee7fa;
    background-image: url("/images/ic_info_24x24.png");
    background-repeat: no-repeat;
    background-position: 4%;
  }
  span[data-type="info"] {
    background-color: 'blue';
  }
  div[data-wrap="button"] {
    width: 50px;
    @include center_mid();
    > div {
      background-color: transparent;
      background-image: url("/images/ic_close_30x30.png");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 16px;
      height: 16px;
      background-size: 16px;
    }
  }

  > div {
    @include flex_direction(row);
    position: relative;
    padding-left: 0 5px;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: $white;
    width: 100%;
    animation-name: web_toast;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-play-state: running;
    > span {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      display: inline-block;
      width: 5px;
    }
  }
  > div[data-mouseevent="leave"] {
    animation-name: web_toast_leave;
  }
  > div:hover {
    animation-name: web_toast_stop;
    animation-play-state: paused;
  }
}

.mobile_web_toast_container {
  position: fixed;
  z-index: 1000000;
  right: 10px;
  width: 280px;
  height: auto;
  margin-top: 100px;

  div[data-type="text"] {
    padding: 24px 0;
    min-height: 64px;
    font-size: 13px;
    flex: 1;
    padding-left: 40px;
    white-space: pre-wrap;
    word-wrap: break-word;
    @include flex_direction(row);
    @include align_items(center);
    color: $black;
    font-size: $large-font-size;
    width: 100%;
    height: 100%;
  }

  div[data-type="success"] {
    background-color: #d2f0dc;
    background-image: url("/images/ic_success_24x24.png");
    background-repeat: no-repeat;
    background-position: 4%;
  }
  span[data-type="success"] {
    background-color: 'green';
  }
  div[data-type="warning"] {
    background-color: #ffefcf;
    background-image: url("/images/ic_warning_24x22.png");
    background-repeat: no-repeat;
    background-position: 4%;
  }
  span[data-type="warning"] {
    background-color: 'yellow';
  }
  div[data-type="error"] {
    background-color: #ffd2d1;
    background-image: url("/images/ic_error_24x24.png");
    background-repeat: no-repeat;
    background-position: 4%;
  }
  span[data-type="error"] {
    background-color: 'red';
  }
  div[data-type="info"] {
    background-color: #cee7fa;
    background-image: url("/images/ic_info_24x24.png");
    background-repeat: no-repeat;
    background-position: 4%;
  }
  span[data-type="info"] {
    background-color: 'blue';
  }
  div[data-wrap="button"] {
    width: 50px;
    @include center_mid();
    > div {
      background-color: transparent;
      background-image: url("/images/ic_close_30x30.png");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 16px;
      height: 16px;
      background-size: 16px;
    }
  }

  > div {
    @include flex_direction(row);
    position: relative;
    padding-left: 0 5px;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: $white;
    width: 100%;
    animation-name: web_toast;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-play-state: running;
    > span {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      display: inline-block;
      width: 5px;
    }
  }
  > div[data-mouseevent="leave"] {
    animation-name: web_toast_leave;
  }
  > div:hover {
    animation-name: web_toast_stop;
    animation-play-state: paused;
  }
}

@keyframes web_toast {
  from {
    -webkit-transform: translate(500px, 0);
  }
  10% {
    -webkit-transform: translate(-10px, 0);
  }
  12% {
    -webkit-transform: translate(0px, 0);
  }
  16% {
    -webkit-transform: translate(0px, 0);
  }
  80% {
    -webkit-transform: translate(0px, 0);
  }
  85% {
    -webkit-transform: translate(-20px, 0);
  }
  to {
    -webkit-transform: translate(500px, 0);
  }
}

@keyframes web_toast_stop {
  from {
    -webkit-transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(0, 0);
  }
}

@keyframes web_toast_leave {
  from {
    -webkit-transform: translate(0px, 0);
  }
  12% {
    -webkit-transform: translate(0px, 0);
  }
  16% {
    -webkit-transform: translate(0px, 0);
  }
  80% {
    -webkit-transform: translate(0px, 0);
  }
  85% {
    -webkit-transform: translate(-20px, 0);
  }
  to {
    -webkit-transform: translate(500px, 0);
  }
}

:export {
  white: $white;
  error: $old-error;
  success: $old-success;
}
