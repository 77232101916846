@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.carModalSearchInputWrap {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 10px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.carModalSearchInput {
  width: 40%;
  height: 36px;
  font-size: 16px;
  padding: 0 10px;
}

.carModalListBody {
  width: 20%;
  box-sizing: border-box;
  border-right: 1px solid $gray-4;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  color: #5f5d5d;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  float: left;
}

.carModalSearchButton {
  border-radius: 0;
  border: none;
  height: 40px;
  width: 50px;
  background-color: $main-color;
}

.select_car_modal_wrap {
  min-width: 700px;
  // height: 660px;
  height: 550px;
  padding: 40px 30px;
  .search_line_wrap {
    @include flex-direction(row);
    justify-content: flex-end;
    .connected_car {
      @include flex-direction(row);
      @include align-items(center);
      height: 36px;
      margin-right: 50px;
      .connect_image_wrap {
        @include center_mid();
        width: 26px;
        height: 26px;
        margin-right: 5px;
        padding: 5px 4px 5px 6px;
        border: solid 1px #efefef;
        border-radius: 26px;
        background-color: $white;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .connect_text {
        font-size: 13px;
        color: #282828;
      }
    }
  }
  .search_input_wrap {
    @include justify-content(flex-end);
  }
  .table_area {
    margin-top: 21px;
    margin-bottom: 10px;
    // height: 436px;
    height: 330px;
    overflow-y: auto;
  }
}

.checkbox_div {
  border-radius: 2px;
  width: 18px;
  height: 18px;
  margin: auto;
  padding-top: 4px;
}

.connected_car_image_wrap {
  @include center_mid();
  // margin-top: 13px;
  width: 26px;
  height: 26px;
  // padding: 5px 4px 5px 6px;
  border: solid 1px #efefef;
  border-radius: 30px;
  background-color: $white;
  img {
    width: 20px;
    height: 20px;
  }
}

.active_checkbox {
  background-color: $sub-color;
  border: none;
}

.inactive_checkbox {
  border: solid 1px $gray-3;
  background-color: $white;
}

.button_area {
  @include flex_direction(row);
  @include justify_content(flex-end);
  margin-top: 20px;
  padding-bottom: 10px;
  .cancel_button {
    @include center_mid();
    width: 74px;
    height: 42px;
    border-radius: 3px;
    border: solid 1px $gray-2;
    margin-right: 15px;
    font-size: 15px;
    cursor: pointer;
  }
  .complete_button {
    @include center_mid();
    width: 108px;
    height: 42px;
    border-radius: 3px;
    background-color: $gray-2;
    color: $white;
    font-size: 15px;
    cursor: pointer;
  }
}

.td_none_attachment {
  height: 120px;
  border: none;
}

.search_wrap {
  @include flex-direction(row);
  // margin-right: 10px;
  align-items: center;
  .group_name {
    font-size: 12px;
    line-height: 12px;
    margin-right: 13px;
  }

  .group_select {
    width: 200px;
    height: 36px;
  }
}
