@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.modal_wrap {
  padding: 30px 40px;

  .modal_text_container {
    width: 286px;
    height: 38px;
    margin: 30px auto 40px;
    font-size: 14px;
    color: #282828;
    text-align: center;
    line-height: 1.57;

    p {
      margin: 0;
    }
  }

  .modal_button_container {
    display: flex;
    @include justify_content(flex-end);

    .modal_confirm {
      width: 108px;
      height: 42px;
      margin-left: 15px;
      font-size: 15px;
    }
  
    .modal_close {
      width:74px;
      height: 42px;
      font-size: 15px;
      color: #575757;
      background-color: #ffffff;
    }
  }
}
