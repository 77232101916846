@import "../node_modules/normalize.css/normalize.css";
@import "./variables.scss";
@import "./mixin.scss";
@import "./thirdPartyStyles/customizedDatePicker.scss";
@import "./thirdPartyStyles/custom_lightbox_style.css";
@import "./ReactSpringBottomSheet.module.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

// @font-face {
//   font-family: "Nanum Barun Gothic";
//   font-weight: 400;
//   src: url("/fonts/NanumSquareRegular.woff") format("woff"), url("/fonts/NanumSquareRegular.ttf") format("truetype");
// }
// @font-face {
//   font-family: "Nanum Barun Gothic";
//   font-weight: 700;
//   src: url("/fonts/NanumSquareBold.woff") format("woff"), url("/fonts/NanumSquareBold.ttf") format("truetype");
// }
// @font-face {
//   font-family: "Nanum Barun Gothic";
//   font-weight: 300;
//   src: url("/fonts/NanumSquareLight.woff") format("woff"), url("/fonts/NanumSquareLight.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Nanum Barun Gothic";
//   font-style: normal;
//   font-weight: 200;
//   src: local("Nanum Barun Gothic UltraLight"), local("Nanum Barun Gothic-UltraLight"),
//     local("NanumBarunGothic UltraLight");
//   src: url("/fonts/NanumBarunGothicUltraLight.woff") format("woff"),
//     url("/fonts/NanumBarunGothicUltraLight.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Nanum Barun Gothic";
//   font-style: normal;
//   font-weight: 300;
//   src: local("Nanum Barun Gothic Light"), local("Nanum Barun Gothic-Light"), local("NanumBarunGothic Light");
//   src: url("/fonts/NanumBarunGothicLight.woff") format("woff"),
//     url("/fonts/NanumBarunGothicLight.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Nanum Barun Gothic";
//   font-style: normal;
//   font-weight: 400;
//   src: local("Nanum Barun Gothic Regular"), local("Nanum Barun Gothic-Regular"), local("NanumBarunGothic Regular");
//   src: url("/fonts/NanumBarunGothic.woff") format("woff"), url("/fonts/NanumBarunGothic.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Nanum Barun Gothic";
//   font-style: normal;
//   font-weight: 700;
//   src: local("Nanum Barun Gothic Bold"), local("Nanum Barun Gothic-Bold"), local("NanumBarunGothic Bold");
//   src: url("/fonts/NanumBarunGothicBold.woff") format("woff"),
//     url("/fonts/NanumBarunGothicBold.ttf") format("truetype");
// }

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: $font-family;
  color: $main-color;
  font-weight: $font-weight-regular;
  font-size: $large-font-size;
}

body * {
  @include box_sizing();
}

div[data-reactroot] {
  height: 100%;
}

pre {
  display: block;
  font-family: $font-family;
  white-space: pre;
}

div,
input,
span,
button,
select,
a {
  outline: none;
}

button {
  -webkit-tap-highlight-color: transparent;
}

input::-ms-clear {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid $gray-4;
}

/* - Chrome ≤56,
 - Safari 5-10.0
 - iOS Safari 4.2-10.2
 - Opera 15-43
 - Opera Mobile >12
 - Android Browser 2.1-4.4.4
 - Samsung Internet
 - UC Browser for Android
 - QQ Browser */
::-webkit-input-placeholder {
  color: $gray-4;
}

/* Firefox 4-18 */
:-moz-placeholder {
  color: $gray-4;
}

/* Firefox 19-50 */
::-moz-placeholder {
  color: $gray-4;
}

/* - Internet Explorer 10–11
 - Internet Explorer Mobile 10-11 */
:-ms-input-placeholder {
  color: $gray-4 !important;
}

/* Edge (also supports ::-webkit-input-placeholder) */
::-ms-input-placeholder {
  color: $gray-4;
}

/* CSS Working Draft */
::placeholder {
  color: $gray-4;
}

select::-ms-expand {
  display: none;
}

/* select:focus::-ms-value {
background-color: transparent;
color: $main-color;
} */

button,
select {
  cursor: pointer;
}
table {
  border-collapse: collapse;
  width: 100%;
  border: none;
  th,
  td {
    border: 1px solid #eeeeee;
    text-align: center;
    padding: 5px 0;
    height: 40px;
  }
  thead {
    th {
      background: $old-gray-1;
      color: $main-color;
      font-size: $large-font-size;
      font-weight: $font-weight-bold;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background: rgba(56, 136, 230, 0.1);
      }
      td {
        &:first-child {
          border-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
      }
    }
    th,
    td {
      font-size: $normal-font-size;
      padding: 10px 0;
      font-weight: $font-weight-regular;
    }
  }
}

a {
  text-decoration: none;
}

.wrap {
  position: relative;
  overflow: hidden;
  min-width: 1100px;
  /* min-width: 1350px; */
  background: $white;
  height: 100%;
  min-height: 830px;
  margin-left: 200px;
}

.mobile_wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: $white;
  height: 100%;
  min-height: 600px;
}

.mobile_container {
  margin: 0 auto;
  padding: 8px 10px;
  height: 100%;
  max-width: 500px;
  text-align: left;
}

.container {
  /* margin: 0 auto; */
  padding: 8px 30px;
  /* padding: 8px 10px; */
  /* width: 1080px; */
  /* width: 100%;
  max-width: 1080px; */
  height: 100%;
  text-align: left;
  margin-bottom: 40px;
}

img,
a {
  -webkit-user-drag: none;
}

div[data-request-type="repair_shop_request"] {
  background-color: #1bc2b7;
}
div[data-request-type="rent_request_accident"] {
  background-color: #98b763;
}
div[data-request-type="cafe_rent_request_accident"] {
  background-color: #98b763;
}
div[data-request-type="insurance_rent_request"] {
  background-color: #7e858f;
}
div[data-request-type="init_insurance_request"] {
  background-color: #7e858f;
}
div[data-request-type="self_insurance_request"] {
  background-color: #7e858f;
}
div[data-request-type="rencar_claim"] {
  background-color: #7e858f;
}
div[data-request-type="renchul_request"] {
  background-color: #7fa0e2;
}
div[data-request-type="field_service_request"] {
  background-color: #7fa0e2;
}
div[data-request-type="meritz_rent_request"] {
  background-color: #7e858f;
}
div[data-request-type="partnered_auto_mobile_request"] {
  background-color: #ffb955;
}
div[data-request-type="auto_mobile_request"] {
  background-color: #ffb955;
}
div[data-request-type="normal_contract"] {
  background-color: #6fca52;
}
div[data-request-type="automobile"] {
  background-color: #ffb955;
}
div[data-request-type="notice"] {
  background-color: #000000;
}
div[data-request-type="kakao"] {
  // 임시로 설정(카카오 )
  background-color: #ffdc4a;
}
div[data-request-type="external"] {
  // 임시로 설정(카카오 )
  background-color: #1f98fb;
}
div[data-request-type="guaranteed_auto_mobile_request"] {
  background-color: #ffa723;
}
div[data-request-type=""] {
  background-color: #ffb955;
}

// 요금표 리스트 미입력시 보더 스타일
.empty_error {
  border: 1px solid red;
}

/* Contract & NormalContract page/photoPage media print global */
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  @page {
    margin: 0;
  }
  .paper {
    margin: 0;
    border: 1px solid black;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  .button_area {
    display: none;
  }
}

// 바텀시트 zindex
[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 200 !important;
}

div[data-rsbs-header] {
  box-shadow: none;
}

:root {
  --new-gray-0: #{$new-gray-0};
  --new-gray-1: #{$new-gray-1};
  --new-gray-2: #{$new-gray-2};
  --new-gray-3: #{$new-gray-3};
  --new-gray-4: #{$new-gray-4};
  --new-gray-5: #{$new-gray-5};
  --new-gray-6: #{$new-gray-6};
  --new-gray-7: #{$new-gray-7};
  --new-gray-8: #{$new-gray-8};

  --new-blue-1: #{$new-blue-1};
  --new-blue-2: #{$new-blue-2};
  --new-blue-3: #{$new-blue-3};
  --new-blue-4: #{$new-blue-4};
  --new-blue-5: #{$new-blue-5};

  --new-red-1: #{$new-red-1};
  --new-red-2: #{$new-red-2};
  --new-red-3: #{$new-red-3};
  --new-red-4: #{$new-red-4};
  --new-red-5: #{$new-red-5};

  --new-green-1: #{$new-green-1};
  --new-green-2: #{$new-green-2};
  --new-green-3: #{$new-green-3};
  --new-green-4: #{$new-green-4};
  --new-green-5: #{$new-green-5};

  --white: #{$white};
}
