@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.common_row_table_wrap {
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 14px;
  position: relative;
  h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: $black;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
    padding: 0;
  }

  &>img {
    width: 100%;
    height: 100%;
  }

  .btn_area {
    @include align_items(center);
    position: absolute;
    top: -4px;
    right: 0;
  }
  
  .table_btn {
    border: none;
    background: #575757;
    color: #fff;
    border-radius: 3px;
    min-width: 50px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 13px;
    margin-left: 10px;
  }

  .table_wrap {
    border-top: 1px solid $black;

    .row_wrap {
      @include align_items(center);
      .flex_double {
        -ms-flex: 2;
        flex: 2;
      }
      .lower_flex {
        -ms-flex: 0.7;
        flex: 0.7;
      }
    }

    .row {
      @include align_items(stretch);
      flex: 1;
      height: 100%;
      min-height: 36px;
      border-bottom: 1px solid #cccccc;
      .label {
        @include center_mid();
        min-width: 140px;
        text-align: center;
        min-height: inherit;
        background: #eeeeee;
        font-size: 14px;
        img {
          width: 100%;
          height: 100%;
        }
        .text {
          margin-right: 5px;
          p {
            width: 130px;
            font-size: 11px;
            word-break: keep-all;
            margin-top: 10px;
          }
          .necessary_area {
            @include align_items(center);
            @include justify_content(center);
            text-align: center;
            position: relative;
            .necessary {
              width: 6px;
              position: relative;
              bottom: 5px;
              right: 5px;
            }
          }
          .circle_number {
            width: 13px;
            height: 13px;
          }

          .left {
            margin-right: 5px;
          }
          
          .bottom {
            position: absolute;
            bottom: -19px;
            left: 50%;
          }
        }
      }

      .row_contents_wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 100%;

        .row_contents {
          @include align_items(center);
          padding-left: 10px;
          width: 100%;
        }  
        .row_notification {
          font-size: 12px;
          display: flex;
          align-items: center;
          border: 1px solid #282828;
          border-radius: 12px;
          padding: 0 10px;
          height: 24px;
        }
      }
    }

    .table_column {
      @include align_items(flex-start);
      -ms-flex-direction: column;
      flex-direction: column;

      div:last-child {
        border-bottom: none !important;
      }
    }

    // .row_wrap {
    //   @include align_items(center);
    //   flex: 1;
    //   min-height: 36px;
    //   border-bottom: 1px solid #cccccc;

    // }
  }
}


.picker {
  top: 120px !important;
}

