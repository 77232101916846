@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.small_style {
  thead {
    th {
      font-size: 11px;
      font-weight: 700;
    }
  }
  tbody {
    th,
    td {
      font-size: 11px;
      font-weight: 400;
    }
  }
}

.table_style {
  thead {
    th {
      font-size: 12px;
      font-weight: 700;
      background-color: $gray-5;
      border-top: 1px solid $gray-3;
      border-left: none;
      border-right: none;
      .checkbox_div {
        border-radius: 2px;
        width: 20px;
        height: 20px;
        margin: 0 auto;
        padding-top: 4px;
        cursor: pointer;
      }
      .active_checkbox {
        background-color: $sub-color;
        border: none;
      }
      .inactive_checkbox {
        border: solid 1px $gray-3;
        background-color: $white;
      }
    }
  }
  tbody {
    th,
    td {
      font-size: 12px;
      font-weight: 400;
      border-left: none;
      border-right: none;
      line-height: 1;
    }
    tr:nth-child(even) {
      background-color: $gray-7;
    }
    tr:hover {
      background: rgba(56, 136, 230, 0.1);
    }
    tr {
      cursor: pointer;
    }
  }
}

.table_scroll_style {
  thead {
    th {
      font-size: 12px;
      font-weight: 700;
      background-color: $gray-5;
      border-top: 1px solid $gray-3;
      border-left: none;
      border-right: none;
    }
  }

  tbody {
    th,
    td {
      font-size: 12px;
      font-weight: 400;
      border-left: none;
      border-right: none;
    }

    tr:nth-child(even) {
      background-color: $gray-7;
    }

    display: block;
    max-height: 600px;
    overflow-y: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.tooltipImg {
  width: 14px;
  height: 14px;
  margin: 0 5px -3px;
}

.overlay {
  @include center_mid();
}

.table_notice_style {
  min-width: 1000px;
  thead {
    th {
      font-size: 12px;
      font-weight: 700;
      background-color: $white;
      border-top: 1px solid $gray-2;
      border-left: none;
      border-right: none;
      .checkbox_div {
        border-radius: 2px;
        width: 18px;
        height: 18px;
        margin: auto;
        padding-top: 4px;
        cursor: pointer;
      }
      .active_checkbox {
        background-color: $sub-color;
        border: none;
      }
      .inactive_checkbox {
        border: solid 1px $gray-3;
        background-color: $white;
      }
    }
  }
  tbody {
    th,
    td {
      font-size: 13px;
      font-weight: 400;
      border: 1px solid $gray-5;
      border-left: none;
      border-right: none;
    }
    td:nth-child(1) {
      text-align: left;
      padding: 0 10px;
    }

    tr {
      height: 44px;

      &:hover {
        cursor: pointer;
        background: rgba(56, 136, 230, 0.1);
      }
    }
  }
}

.head_fix {
  position: sticky;
  z-index: 9;
  top: -1px;
}
