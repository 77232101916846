@import "../../styles/variables.scss";

.modal_container {
  height: 100%;
  padding: 20px;
}

.close_button_container {
  display: flex;
  justify-content: flex-end;
}

.close_button {
  width: 80px;
  height: 30px;
  margin-top: 20px;
  background-color: $white;
  border: solid 1px $black;
  border-radius: 3px;
  cursor: pointer;
}
