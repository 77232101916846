@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.info_text {
  display: flex;
  font-size: 18px;
  font-weight: bold;
}

.reconfirm_text {
  margin-top: 16px;
  font-size: 18px;
  line-height: 1.44;
}

.caution {
  @include justify_content(center);
  font-size: 14px;
  color: #282828;
}
