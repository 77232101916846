@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";
.center_mid {
  @include center_mid();
}

.side_menu_wrap {
  width: 200px;
  background-color: $main-color;
  color: $white;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  // border-right: 1px solid $main-color;
  margin-top: 50px;
}

.side_menu_list_cover {
  width: 100%;
  height: calc(100% - 455px);
  overflow: auto;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #48515e;
    // background: $old-sub-color;
  }
  &::-webkit-scrollbar-track {
    background: $main-color;
  }

  // IE
  & {
    scrollbar-face-color: #48515e;
    // scrollbar-face-color: $old-sub-color;
    scrollbar-track-color: $main-color;
  }
}

.side_menu_logo {
  @include center_mid();
  @include justify_content(flex-start);
  padding: 0 20px;
  cursor: pointer;
  border-bottom: 1px solid $old-gray-1;
}

.side_menu_company_info {
  height: auto;
  padding-bottom: $smaller-font-size;
}

.side_menu_company_number {
  height: auto;
  font-size: 13px;
  padding: 20px;
}

.side_menu_company {
  font-size: $large-font-size;
  font-weight: 700;
  padding: 20px 20px 0;
}

.side_menu_cash_area {
  // border: 1px solid $old-gray-1;
  margin: 0 20px;
  // padding: $smaller-font-size 20px;
  // cursor: pointer;
}

.side_menu_cash_area:hover {
  border-color: #a2a2a2;
}

.side_menu_cash_row {
  @include flex_direction(row);
  margin-top: 2px;
}

.side_menu_cash_label {
  width: 45%;
  @include center_mid();
  @include justify_content(flex-start);
  // font-size: $small-font-size;
  font-size: $normal-font-size;
  font-weight: 300;
  line-height: 25px;

  .cash_charge_btn {
    border: 1px solid #ff9c34;
    border-radius: 5px;
    background: #ff9c34;
    height: 20px;
    margin-left: 5px;
    font-size: 10px;
    line-height: 12px;
    color: $white;
    cursor: pointer;
  }
}

.side_menu_cash_value {
  width: 55%;
  @include center_mid();
  @include justify_content(flex-end);
  // font-size: $small-font-size;
  font-size: $normal-font-size;
  font-weight: 300;
  line-height: 25px;
}

.side_menu_company_history_area {
  width: 100%;
  height: 50px;
  padding-bottom: 10px;
  border-bottom: 1px solid $main-color;
}

.side_menu_company_history_button {
  width: calc(100% - 40px);
  height: 36px;
  margin: 0 20px;
  background-color: $main-color;
  color: $gray-5;
  font-size: 14px;
  border: 1px solid $gray-5;
  border-radius: 5px;
}

.side_menu_search_car_area {
  width: 100%;
  height: 55px;
  padding-bottom: 15px;
  border-bottom: 1px solid $main-color;
}

.side_menu_search_car_button {
  width: calc(100% - 40px);
  height: 36px;
  margin: 0 20px;
  background-color: $gray-5;
  color: $main-color;
  font-size: 14px;
  border: 1px solid $gray-5;
  border-radius: 5px;
}

.side_menu_row {
  height: 50px;
  @include center_mid();
  @include justify_content(flex-start);
  padding: 0 20px;
  cursor: pointer;
  background-color: $main-color;
  color: $white;
  font-weight: 300;
  font-size: 14px;
  // background-repeat: no-repeat;
  // background-position: 90% center;
}

.side_menu_sub_menu_row {
  padding: $smaller-font-size 30px;
  font-weight: 300;
  font-size: 13px;
  cursor: pointer;
  // background-repeat: no-repeat;
  // background-position: 8% center;
  background-color: $gray-1;
  color: $white;
}

.side_menu_sub_menu_row:hover {
  // font-weight: 700;
  color: $sub-color;
}

.side_menu_term_wrap {
  width: 100%;
  height: 50px;
  @include center_mid();
  @include flex_direction(row);
  box-sizing: border-box;
  border-top: 1px solid $old-gray-1;
  border-bottom: 1px solid $old-gray-1;
}

.side_menu_privacy_treat_area {
  @include box_sizing();
  width: 60%;
  height: 100%;
}

.side_menu_term_area {
  width: 40%;
  height: 100%;
  @include box_sizing();
  border-left: 1px solid $old-gray-1;
}

.side_menu_term_button {
  height: 100%;
  @include center_mid();
  cursor: pointer;
  font-size: $small-font-size;
  color: $old-gray-2;
}

.side_menu_term_button:hover {
  color: #515151;
}

.side_menu_call_center_wrap {
  // border-top: 1px solid $main-color;
  padding: 0px 20px 20px 20px;
  .side_menu_tel_number {
    //font-weight: bold;
    font-weight: 300;
    margin-top: 5px;
    font-size: $large-font-size;
    line-height: 25px;
  }
  .side_menu_tel_time {
    font-size: $small-font-size;
    line-height: 14px;
    padding-top: 3px;
  }
  .side_menu_link_button_wrap {
    @include flex-direction(row);
    @include align_items(center);
    @include justify_content(space-between);
    margin: 15px 0;
    .blog_logo {
      @include align_items(center);
    }
  }
}

.side_menu_footer {
  width: 200px;
  height: 115px;
  position: fixed;
  bottom: 0;
  z-index: 10000;
}

.menuItemContainer {
  cursor: pointer;
  @include flex_direction(row);
  @include align_items(center);
  @include justify_content(space-between);
  height: 40px;
}
.openMenu {
  display: block;
  transition: 0.4s;
}

.closeMenu {
  display: none;
  max-height: 0px;
  background-color: $main-color;
}

.side_menu_mark_count {
  @include center_mid();
  height: 22px;
  margin-top: 2px;
  border-radius: 10px;
  background-color: #e05656;
  color: $white;
  margin-left: 10px;
  padding: 0 7px;
  font-size: 12px;
  min-width: 25px;
}