@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.text {
  font-size: 17px;
  line-height: 1.5;
  span {
    font-size: 15px;
  }
}

.text_area {
  margin-top: 15px;
  font-size: 13px;
  line-height: 1.15;
}

.no_show_process_info {
  padding-top: 20px;
  font-size: $h4-font-size;

  .no_show_process_info_title {
    font-weight: $font-weight-bold;
  }

  .article_wrap {
    @include flex_direction(row);
    @include align_items(flex-start);
  }

  article {
    padding-bottom: 25px;
  }
}

.warning_box {
  @include flex_direction(row);
  img {
    width: 20px;
    height: 26px;
    margin-right: 8px;
  }
  border-radius: 8px;
  padding: 15px 27px;
  font-size: $h4-font-size;
  line-height: 24px;
  background-color: kakao('red-bold');
  color: $white;
}