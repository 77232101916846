@import '../../../../styles/variables.scss';
@import '../../../../styles/mixin.scss';

.center_mid {
  justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
}

.businessListHeader {
  width: 20%;
  box-sizing: border-box;
  border-right: 1px solid $gray-4;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #5f5d5d;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  float: left;
}

.businessListBody {
  width: 20%;
  box-sizing: border-box;
  border-right: 1px solid $gray-4;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  color: #5f5d5d;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  float: left;
}

.dateModifyInfoTitle {
  line-height: 30px;
  font-size: 11px;
  margin-left: 10px;
  color: #757575;
  font-weight: 300;
}

.dateModifyInfoCurrentValue {
  line-height: 30px;
  font-size: 12px;
  margin-left: 5px;
  color: #757575;
  font-weight: 300;
}

.set_done_date_modal_row_label {
  line-height: 40px;
  font-size: 15px;
  color: #757575;
  font-weight: 400;
  width: 30%;
}

.set_done_date_modal_row_value {
  width: 70%;
  height: 100%;
  display: flex;
  display: -ms-flex;
  flex-direction: row !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
}

.dateDepositCostModalInputWrap {
  text-align: center;
  padding-top: 20px;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.dateSetDoneModalInputWrap {
  width: 100%;
  text-align: center;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.return_gas_charge_wrap {
  width: 100%;
  text-align: center;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.dateModifyInputWrap {
  @include align_items(center);
  height: 40px;
  margin-bottom: 10px;
  padding: 5px 60px;
  .dateModifyDivTitle {
    display: block;
    min-width: 80px;
  }
  .dateModifyDateInput {
    height: 100%;
  }
  .dateModifyTimeSelect {
    height: 100%;
  }
}

.dateModifyDateInput {
  height: 26px;
  margin: 0px 10px;
  border-radius: 5px;
  border: 1px solid $gray-4;
  padding-left: 10px;
  width: 140px;
  display: inline-block;
  font-size: 14px;
}

.dateModifyTimeSelect {
  border-radius: 5px;
  border: 1px solid $gray-4;
  margin-right: 5px;
  display: inline-block;
  font-size: 14px;
}

.return_gas_charge_select {
  width: 100px;
  border: 0.5px solid black;
}

.setCostTypeHeaderDiv {
  line-height: 40px;
  font-size: 17px;
  width: 50%;
  text-align: center;
  display: inline-block;
}

.selectCostTypeTypeButton {
  width: 25%;
  display: inline-block;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #757575;
  font-size: 15px;
  box-sizing: border-box;
  cursor: pointer;
}

.costTypeListRadioButtonArea {
  width: 8%;
  height: 100%;
  font-size: 13px;
  float: left;
}

.costTypeListCarName {
  width: 62%;
  height: 100%;
  border-right: 1px solid #b6b6b6;
  box-sizing: border-box;
  font-size: 13px;
  float: left;
}

.costTypeListCost {
  width: 30%;
  height: 100%;
  display: inline-block;
  font-size: 16px;
  float: left;
}

.costTypeListCarName > div {
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;
  line-height: 20px;
}

.costTypeListCost > div {
  width: calc(100% - 40px);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 20px;
}

.costTypeListRadioButtonArea > div {
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 20px;
}

.costTypeListRadioButtonArea > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  line-height: 20px;
}

.DirectSetCostTypeInputArea {
  width: calc(100% - 40px);
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 20px;
}

.DirectSetCostTypeInputArea > div {
  padding: 5px;
  font-size: 16px;
}

.DirectSetCostTypeInputArea > div input {
  width: 260px;
  height: 20px;
  padding: 10px;
  border: 1px solid #f2f2f2;
  margin-right: 8px;
  font-size: 14px;
}

.fax_modal_row {
  width: 100%;
  height: 40px;
  line-height: 40px;
}

.fax_row_label {
  display: inline-block;
  width: 40%;
  height: 100%;
  font-size: 16px;
  text-align: center;
}

.fax_status {
  display: inline-block;
  width: 60%;
  height: 100%;
  font-size: 16px;
  text-align: left;
  color: #b6b6b6;
}

.fax_result_area {
  width: 100%;
  height: 100px;
  background-color: #f2f2f2;
  text-align: center;
}

.fax_result {
  display: inline-block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
}

.fax_result_success {
  display: inline-block;
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-size: 18px;
}

.fax_error_code_wrap {
  width: 100%;
  height: 30px;
  text-align: center;
}

.fax_error_code {
  width: 100px;
  height: 26px;
  margin-top: 2px;
  margin-bottom: 2px;
  border: none;
  border-radius: 30px;
  line-height: 26px;
  font-size: 12px;
  background-color: #b8b8b8;
  color: $white;
  margin: 0 auto;
}

.fax_rencar_tel {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.memo_row {
  word-wrap: break-word;
  word-break: break-all;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: stretch;
  -webkit-align-items: flex-start;
  -ms-align-items: flex-start;
}

.set_done_date_modal_content {
  @extend .center_mid;
  flex-direction: column !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  min-height: 160px;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.long_term_payment_info_modal_wrap {
  padding: 30px 40px;
  .table_wrap {
    height: 80px;
    margin-bottom: 15px;
  }
  .notice_text {
    font-size: 13px;
    line-height: 1.46;
  }
  .button_container {
    @include flex_direction(row);
    @include justify-content(flex-end);
    margin-top: 40px;
    .button {
      text-align: center;
      height: 42px;
      line-height: 42px;
      border-radius: 3px;
      cursor: pointer;
    }
    .cancel_button {
      width: 74px;
      border: 1px solid $gray-2;
      color: $gray-2;
      margin-right: 15px;
    }
    .save_button {
      width: 108px;
      background-color: $gray-2;
      color: $white;
    }
  }
}

.long_term_notice_download_modal {
  padding: 30px 40px;
  .file_download_button_wrap {
    @include flex_direction(row);
    @include justify-content(space-between);
    margin-bottom: 30px;
    .file_download_button {
      @include flex_direction(column);
      @include align_items(center);
      width: 130px;
      height: 103px;
      background-color: $gray-5;
      cursor: pointer;
      .label {
        padding: 23px 0 15px;
      }
      img {
        width: 22px;
        height: 19px;
      }
    }
  }
  .notice_text {
    font-size: 13px;
    line-height: 1.46;
  }
  .close_button_wrap {
    @include justify-content(flex-end);
    .close_button {
      @include center_mid();
      width: 74px;
      height: 42px;
      margin-top: 40px;
      border-radius: 3px;
      border: solid 1px $gray-2;
      cursor: pointer;
    }
  }
}

.select_business_modal_wrap {
  min-width: 700px;
  height: 60vh;
  padding: 40px 30px;
  .table_area {
    margin-bottom: 10px;
    height: 436px;
    overflow-y: auto;
  }
}

.checkbox_div {
  border-radius: 2px;
  width: 18px;
  height: 18px;
  margin: auto;
  padding-top: 4px;
}

.active_checkbox {
  background-color: $sub-color;
  border: none;
}

.inactive_checkbox {
  border: solid 1px $gray-3;
  background-color: $white;
}

.button_area {
  @include flex_direction(row);
  @include justify_content(flex-end);
  margin-top: 20px;
  .cancel_button {
    @include center_mid();
    width: 74px;
    height: 42px;
    border-radius: 3px;
    border: solid 1px $gray-2;
    margin-right: 15px;
    cursor: pointer;
  }
  .complete_button {
    @include center_mid();
    width: 108px;
    height: 42px;
    border-radius: 3px;
    background-color: $gray-2;
    color: $white;
    cursor: pointer;
  }
}

.contract_switch_return_modal_wrap,
.contract_switch_day_month_modal_wrap {
  padding: 30px 40px;
  .customer_info_wrap {
    @include flex_direction(row);
    @include align_items(center);

    p {
      margin: 0;
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }
    p:nth-child(2) {
      margin: 0 8px 0 8px;
    }
  }

  .sub_text {
    margin-top: -35px;
    margin-bottom: 40px;
    p {
      margin: 0;
      font-size: 13px;
      line-height: 19px;
    }
  }

  .btn_wrap {
    text-align: end;
    button {
      height: 42px;
    }

    :first-child {
      width: 74px;
      border: 1px solid $gray-2;
      border-radius: 3px;
      background-color: $white;
      color: $gray-2;
    }
    :last-child {
      width: 108px;
      border: none;
      border-radius: 3px;
      background-color: $gray-2;
      color: $white;
      margin-left: 15px;
    }
  }

  .table_wrap {
    margin-top: 20px;
  }
}

.td_none_attachment {
  height: 120px;
  border: none;
}

.common_modal_wrap {
  @include justify-content(space-between);
  @include flex-direction(column);
  padding: 30px 40px;
  .footnote_wrap {
    @include justify-content(flex-end);
    font-size: 12px;
    color: #929292;
  }
  .payment_info {
    font-size: 13px;
  }
  .description_wrap {
    margin: 15px 0 19px 0;
    padding: 12px 0 12px 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
    .description_title_wrap {
      @include flex-direction(row);
      @include align-items(center);
      margin-bottom: 9px;
      font-family: "NanumBarunGothic";
      font-size: 13px;
      font-weight: bold;
      color: #282828;
      img {
        margin-right: 6px;
        width: 12px;
        height: 20px;
      }
      .title_text {
        margin-top: 4px;
      }
    }
    .connect_description {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 1.5;
      color: #282828;
    }
    .connect_announcement {
      font-size: 12px;
      color: #929292;
    }
  }
  .oil_title {
    @include justify-content(flex-start);
    font-size: 14px;
    font-weight: bold;
    color: #282828;
  }
  .oil_cost_wrap {
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(flex-start);
    flex: 1;
    width: 100%;
    height: 100px;
    margin: 10px 0;
    padding: 18px 25px 18px 25px;
    border-radius: 5px;
    background-color: #f9f9f9;
    .oil_description_wrap {
      @include flex-direction(row);
      // @include justify-content(flex-start);
      @include align-items(space-between);
      height: 100%;
      width: 50%;
      .oil_type {
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(space-between);
        width: 170px;
        height: 100%;
        font-size: 14px;
        color: #929292;
      }
      .oil_value {
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(space-between);
        height: 100%;
        font-size: 14px;
        color: #282828;
      }
    }
    .oil_cost_content_wrap {
      @include flex-direction(column);
      @include justify-content(space-between);
      height: 100%;
      margin-left: 10px;
      padding-left: 20px;
      border-left: solid 1px #c5c0bc;
      .oil_cost_title {
        font-size: 14px;
        color: #929292;
      }
      .final_oil_cost_wrap {
        .positive_oil_cost {
          font-size: 18px;
          font-weight: bold;
          color: #007de8;
        }
        .negative_oil_cost {
          font-size: 18px;
          font-weight: bold;
          color: #e05656;
        }
        .oil_cost_text {
          margin-left: 10px;
          font-size: 12px;
          color: #282828;
        }
      }
    }
    .fail_content_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      width: 100%;
      
      font-size: 14px;
      color: #282828;
    }
  }
  .oil_cost_table_footnote {
    @include flex-direction(row);
    @include align-items(center);
    margin-bottom: 40px;
    img {
      width: 12px;
      height: 20px;
      margin-right: 6px;
    }
    .oil_cost_table_footnote_text {
      font-size: 12px;
      color: #282828;
      margin-top: 4px;
    }
  }
}

.modify_total_cost_input_wrap {
  @include justify-content(space-between);
  @include align_items(center);
}

.modify_total_cost_unit {
  font-size: 14px;
}

.connect_request_table {
  thead {
    th {
      font-size: 11px;
      font-weight: 700;
    }
  }
  tbody {
    th,
    td {
      font-size: 11px;
      font-weight: 400;
    }
  }
}

.requestTypeWrap {
  @include flex_direction(row);
  @include center_mid();
}

.requestTypeColor {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.contract_component_title {
  width: auto;
  display: inline-block;
  font-size: 24px;
  margin: 10px 0;
  margin-top: 60px;
  font-weight: 300;
  float: left;
}

.contract_input_container {
  width: 100%;
  height: 100%;
  border-top: 2px solid #b8b8b8;
  border-bottom: 2px solid #b8b8b8;
  float: left;
}

.extend_contract_wrap {
  padding: 40px;
  padding-top: 10px;

  .add_text {
    font-size: 12px;
    margin: 0;
    padding: 0;
    line-height: 1.4;
    color: $main-color;
  }

  .btn_area {
    @include align_items(center);
    margin-top: 20px;
    margin-bottom: 30px;
    button {
      font-size: 15px;
      background: none;
      border: none;
      padding: 12px 27px;
      border-radius: 3px;
      &:first-child {
        margin-left: auto;
        border: 1px solid $gray-2;
        color: $gray-2;
      }
      &:last-child {
        margin-left: 10px;
        background: $gray-2;
        color: white;
      }
    }
  }
}

.notice_kakao_deposit_wrap {
  word-break: keep-all;
  padding: 30px;

  p {
    margin: 0;
    line-height: 1.56;
    font-size: 18px;
  }

  .confirm_button {
    right: 0px;
    width: 100px;
    height: 42px;
    margin: 67px 0 0 360px;
    background-color: $gray-2;
    color: $white;
    outline: none;
    border-radius: 3px;
  }
}

.car_return_fail_wrap {
  @include center_mid();
  padding: 30px 40px;

  .car_return_fail_text {
    margin: 0;
    line-height: 1.57;
    font-size: 18;
  }

  .confirm_button {
    right: 0px;
    width: 100px;
    height: 42px;
    margin: 40px 0 0 360px;
    background-color: $gray-2;
    color: $white;
    outline: none;
    border-radius: 3px;
  }
}

.no_car_return_request_wrap {
  @include flex_direction(column);
  padding: 30px 40px;

  p {
    font-size: 18px;
    line-height: 1.56;
    margin: 0;
  }

  .no_return_request_car_info {
    margin-bottom: 30px;

    .no_car_return_request_text {
      color: red;
      font-weight: bold;
    }
  }

  .confirm_button {
    width: 110px;
    height: 42px;
    margin: 50px 0 0 330px;
    background-color: $gray-2;
    color: $white;
    outline: none;
    border-radius: 3px;
  }
}

.claim_insu_call_modal_contents {
  font-size: 15px;
  line-height: 25px;
  text-align: left;
}

.normal_contract_return_confirm_modal_wrap {
  padding: 30px 40px;

  .confirm_text {
    font-size: 16px;
    line-height: 1.44;
  }

  .input {
    display: flex;
    flex: 2.6;
    padding: 14px 12px 13px;
    font-size: 13px;
    height: auto;
  }

  .confirm_button {
    @include center_mid();
    display: flex;
    flex: 1;
    font-size: 15px;
    margin-left: 15px;
    padding: 12px 20px;
    color: $white;
    border: 1px solid $gray-2;
    border-radius: 3px;
    font-weight: normal;
    height: auto;
  }

  .input_container {
    display: flex;
    margin-top: 12px;
  }

  .input_label {
    font-size: 16px;
    margin-top: 40px;
  }
}

.payment_info_modal {
  padding: 30px 30px;

  p {
    margin: 0 0 5px 0;
  }

  .confirm_button {
    font-size: 15px;
    width: 120px;
    height: 42px;
    margin: 20px 0 0 270px ;
  }
}
