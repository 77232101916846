
@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.skeleton {
  border: 1px solid #efefef;
}

// call_card 
.call_card {
  width: 304px;
  height: 375px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 28px 30px;
}

.call_card_line1 {
  @extend .loading;
  width: 65px; 
  height: 20px;
  margin-bottom: 10px;
}

.call_card_line2 {
  @extend .loading;
  width: 118px;
  height: 28px;
  margin-bottom: 10px;
}

.call_card_line3 {
  @extend .loading;
  height: 22px; 
  margin-bottom: 20px;

  :nth-last-child {
    margin-bottom: 0
  }
}

.call_card_block {
  display: flex; 
  justify-content: space-between; 
  background: #fff;
  margin-bottom: 20px;

  div {
    @extend .loading;
    width: 115px;
    height: 125px; 
  }
}


// common
.loading {
  border-radius: 8px;
  background: #f6f6f6;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation-name: ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

// .banner {
//   width : 100%;
//   height : 200px;
// }

// .text {
//   width : 100%;
//   height : 12px;
// }

// .title {
//   width: 50%;
//   height: 20px;
//   margin-bottom: 15px;
// }

// .avatar{
//   width: 100px;
//   height: 100px;
//   border-radius: 50%;
// }

// .thumbnail {
//   width: 100px;
//   height : 100px;
// }
