.loading_wrap {
  position: fixed;
  z-index: 19000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
}

.loading {
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  position: relative;
  top: calc(50% - 50px);
  left: 50%;
  width: 100px;
  height: auto;
}
