@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.center_mid {
  justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
}

.businessListHeader {
  width: 20%;
  box-sizing: border-box;
  border-right: 1px solid $gray-4;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #5f5d5d;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  float: left;
}

.businessListBody {
  width: 20%;
  box-sizing: border-box;
  border-right: 1px solid $gray-4;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  color: #5f5d5d;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  float: left;
}

.dateModifyInfoTitle {
  line-height: 30px;
  font-size: 11px;
  margin-left: 10px;
  color: #757575;
  font-weight: 300;
}

.dateModifyInfoCurrentValue {
  line-height: 30px;
  font-size: 12px;
  margin-left: 5px;
  color: #757575;
  font-weight: 300;
}

.set_done_date_modal_row_label {
  line-height: 40px;
  font-size: 15px;
  color: #757575;
  font-weight: 400;
  width: 30%;
}

.set_done_date_modal_row_value {
  width: 70%;
  height: 100%;
  display: flex;
  display: -ms-flex;
  flex-direction: row !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
}

.dateModifyDivTitle {
  line-height: 40px;
  font-size: 15px;
  color: #757575;
  font-weight: 400;
}

.dateDepositCostModalInputWrap {
  // height: 40px;
  text-align: center;
  padding-top: 20px;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.dateSetDoneModalInputWrap {
  width: 100%;
  text-align: center;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.return_gas_charge_wrap {
  width: 100%;
  text-align: center;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.dateModifyInputWrap {
  @include align_items(center);
  height: 40px;
  margin-bottom: 10px;
  padding: 5px 60px;
  .dateModifyDivTitle {
    display: block;
    min-width: 80px;
  }
  .dateModifyDateInput {
    height: 100%;
  }
  .dateModifyTimeSelect {
    height: 100%;
  }
  // height: 40px;
  // text-align: center;
  // margin-top: 30px;
  // display: flex;
  // display: -ms-flex;
  // -webkit-box-orient: horizontal;
  // -webkit-box-direction: normal;
  // flex-direction: row;
  // -ms-flex-direction: row;
  // -webkit-box-pack: justify;
  // -ms-flex-pack: justify;
  // justify-content: center;
  // -webkit-justify-content: center;
  // -ms-justify-content: center;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // align-items: center;
  // -webkit-align-items: center;
  // -ms-align-items: center;
}

.dateModifyDateInput {
  height: 26px;
  margin: 0px 10px;
  border-radius: 5px;
  border: 1px solid $gray-4;
  padding-left: 10px;
  width: 140px;
  display: inline-block;
  font-size: 14px;
}

.dateModifyTimeSelect {
  // height: 70%;
  border-radius: 5px;
  border: 1px solid $gray-4;
  // width: 40px;
  margin-right: 5px;
  display: inline-block;
  font-size: 14px;
}

.return_gas_charge_select {
  width: 100px;
  border: 1px solid black;
}

.setCostTypeHeaderDiv {
  line-height: 40px;
  font-size: 17px;
  width: 50%;
  text-align: center;
  display: inline-block;
}

.selectCostTypeTypeButton {
  width: 25%;
  display: inline-block;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #757575;
  font-size: 15px;
  box-sizing: border-box;
  cursor: pointer;
}

.costTypeListRadioButtonArea {
  width: 8%;
  height: 100%;
  font-size: 13px;
  float: left;
}

.costTypeListCarName {
  width: 62%;
  height: 100%;
  border-right: 1px solid #b6b6b6;
  box-sizing: border-box;
  font-size: 13px;
  float: left;
}

.costTypeListCost {
  width: 30%;
  height: 100%;
  display: inline-block;
  font-size: 16px;
  float: left;
}

.costTypeListCarName > div {
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;
  line-height: 20px;
}

.costTypeListCost > div {
  width: calc(100% - 40px);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 20px;
}

.costTypeListRadioButtonArea > div {
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 20px;
}

.costTypeListRadioButtonArea > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  line-height: 20px;
}

.line_middle_gray {
  margin: 30px 40px 0 40px;
  border-top: 1px solid $gray-5;
  height: 1px;
}

.fax_modal_row {
  @include flex_direction(column);
  @include justify_content(flex-start);
  @include align_items(center);
  width: 100%;
}

.fax_row_label {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 16px;
  text-align: left;
  padding: 30px 40px 20px 40px;
}

.fax_status {
  display: inline-block;
  width: 60%;
  height: 100%;
  font-size: 16px;
  text-align: left;
  color: #b6b6b6;
}

.fax_result_area {
  width: 100%;
  background-color: $gray-7;
  text-align: center;
  padding: 25px 0;
  margin-top: 30px;
}

.fax_result_message {
  @include justify_content(center);
  width: 100%;
  line-height: 22px;
  font-size: 16px;
  p {
    margin: 0;
    font-weight: bold;
    &[color-data="success"] {
      color: $blue;
    }
    &[color-data="cancelled"] {
      color: $red;
    }
  }
}

.fax_error_code_wrap {
  width: 67px;
  height: 27px;
  text-align: center;
  background-color: $gray-5;
}

.fax_error_code {
  width: 67px;
  height: 27px;
  margin-top: 2px;
  margin-bottom: 2px;
  border: none;
  line-height: 26px;
  font-size: 12px;
  background-color: $gray-5;
  color: #b2b2b2;
  margin: 15px auto;
}

.fax_rencar_tel {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.fax_center_notice {
  color: $gray-2;
  font-size: 13px;
  line-height: 18px;
  p {
    margin: 0;
  }
}

.fax_modal_buttons_wrap {
  @include justify_content(flex-end);
  @include align_items(center);
  width: 100%;
  height: 100px;
  padding: 0 40px;

  button {
    width: 108px;
    height: 42px;
    background-color: $gray-2;
    color: $white;
    font-size: 15px;
    line-height: 18px;
    border: none;
    border-radius: 3px;
  }
}

.set_done_date_modal_content {
  @extend .center_mid;
  flex-direction: column !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  min-height: 160px;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.download_list_wrap {
  @include flex-direction(row);
  @include justify-content(space-between);
  min-height: 180px;
  overflow-y: auto;
  flex-wrap: wrap;
  padding: 20px 50px;
  .download_item_wrap {
    @include flex-direction(row);
    width: 49%;
    height: 40px;
    .checkbox {
      margin-top: 9px;
      margin-right: 10px;
      border-radius: 3px;
      width: 20px;
      height: 20px;
      position: relative;
    }
    .downloadListBody {
      box-sizing: border-box;
      text-align: center;
      font-size: 15px;
      font-weight: 300;
      color: #5f5d5d;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      float: left;
    }
    .downloadListLabel {
      @include align_items(center);
    }
  }
}

.claim_select_fax_modal_wrap {
  width: 100%;
  height: 100%;
  padding: 25px 40px;
  .claim_select_fax_content_container {
    @include flex-direction(row);
    @include justify-content(space-between);
    flex-wrap: wrap;
    padding-bottom: 18px;

    .claim_select_fax_content {
      @include justify_content(center);
      border-top: 0.1px solid $gray-3;
      flex-wrap: wrap;
    }
  }
  .claim_select_option_wrap {
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align_items(flex-start);
    margin-bottom: 20px;

    .insu_type_wrapper {
      @include justify_content(space-between);
      width: 100%;
    }
    .claim_select_option_label {
      margin-bottom: 3px;
      span {
        color: #ffa728;
      }
    }
    .radio_option_wrap {
      @include flex-direction(row);
      @include justify_content(flex-start);
      @include align_items(center);
      width: 50%;
      height: 54px;
      cursor: pointer;

      .radio_box {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: solid 1px #727272;
        margin-right: 9px;
      }
      .radio_box_selected {
        width: 14px;
        height: 14px;
        border-radius: 10px;
        background-color: $sub-color;
        margin: 2px 0 0 2px;
      }
    }
  }
  .claim_select_fax_notice_wrap {
    @include center_mid();
    background-color: #eaeaea;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    color: $black;
    padding: 17px 22px;
    p {
      margin: 0;
    }
  }
  .send_button_warp {
    @include justify_content(flex-end);
    padding: 25px 0 5px 0;

    button {
      height: 42px;
      font-size: 15px;
      line-height: 18px;
      border-radius: 3px;
    }
    :first-child {
      border: 1px solid $gray-2;
      color: $gray-2;
      background-color: $white;
      width: 74px;
    }
    :last-child {
      margin-left: 15px;
      border: 1px solid $gray-2;
      color: $white;
      background-color: $gray-2;
      width: 108px;
    }
  }
}

.set_cost_type_modal_wrap {
  padding: 40px 30px;
  min-width: 455px;
  .set_cost_type_tab_wrap {
    @include flex_direction(row);
    margin-bottom: 39px;
    cursor: pointer;
    .cost_type_button {
      @include center_mid();
      flex: 1;
      height: 40px;
    }
    .select_cost_type_tab {
      color: $gray-2;
      border-bottom: 2px solid $gray-2;
    }
    .none_select_cost_type_tab {
      color: $gray-4;
      border-bottom: 2px solid $gray-4;
    }
  }
}

.car_type_cost_wrap {
  margin-top: 25px;
  flex-grow: 1;

  @include flex_direction(column);
  .lotte_cost_notice_text {
    @include justify_content(flex-end);
    margin-bottom: 15px;
    font-size: 13px;
    color: red;
  }
  .car_type_select_button_wrap {
    @include flex_direction(row);
    font-size: 13px;
    .car_type_select_button {
      @include center_mid();
      flex: 1;
      height: 36px;
      cursor: pointer;
    }
  }
  .table_area {
    margin-top: 21px;
    height: 370px;
    overflow-y: auto;
  }
}

.direct_cost_wrap {
  margin-top: 47px;
  flex-grow: 1;
  @include flex_direction(column);
  .cost_input_container {
    padding: 20px 30px;
    border-radius: 3px;
    background-color: $gray-6;
    .notice_text {
      font-size: 14px;
    }
    .cost_input_wrap {
      margin-top: 15px;
      .cost_input {
        width: 290px;
        height: 36px;
        margin-right: 15px;
        padding-left: 10px;
      }
      ::placeholder {
        font-size: 13px;
      }
    }
  }
  .notice_text_area {
    margin-top: 30px;
    .alert_text {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1.43;
      color: $red;
    }
    .notice_text {
      font-size: 14px;
      line-height: 1.43;
    }
  }
}

.select_button {
  background-color: $gray-2;
  color: $white;
}
.none_select_button {
  background-color: $white;
  color: $gray-2;
  border: 1px solid $gray-2;
}

.checkbox_div {
  border-radius: 2px;
  width: 18px;
  height: 18px;
  margin: auto;
  padding-top: 4px;
}

.active_checkbox {
  background-color: $sub-color;
  border: none;
}

.inactive_checkbox {
  border: solid 1px $gray-3;
  background-color: $white;
}

.button_area {
  @include flex_direction(row);
  @include justify_content(flex-end);
  margin-top: 20px;
  .cancel_button {
    @include center_mid();
    width: 74px;
    height: 42px;
    border-radius: 3px;
    border: solid 1px $gray-2;
    margin-right: 15px;
    cursor: pointer;
  }
  .complete_button {
    @include center_mid();
    width: 90px;
    height: 42px;
    border-radius: 3px;
    background-color: $gray-2;
    color: $white;
    cursor: pointer;
  }
}

.claim_cost_button_area {
  margin-top: auto;
}

// 일반전환 모달
.contract_switch_to_normal_modal_wrap {
  min-width: 600px;
  padding: 30px 40px;
  .customer_info_wrap {
    @include flex_direction(row);
    @include justify_content(flex-start);
    @include align_items(center);

    p {
      margin: 0;
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }
    p:nth-child(2) {
      margin: 0 8px 0 8px;
    }
  }

  .sub_text {
    margin-top: -35px;
    margin-bottom: 40px;
    p {
      margin: 0;
      font-size: 13px;
      line-height: 19px;
    }
  }

  .btn_wrap {
    text-align: end;
    button {
      height: 42px;
    }

    :first-child {
      width: 74px;
      border: 1px solid $gray-2;
      border-radius: 3px;
      background-color: $white;
      color: $gray-2;
    }
    :last-child {
      width: 108px;
      border: none;
      border-radius: 3px;
      background-color: $gray-2;
      color: $white;
      margin-left: 15px;
    }
  }
}

.common_modal_wrap {
  @include justify-content(space-between);
  @include flex-direction(column);
  height: 100%;
  padding: 30px 40px;
}

.return_car_modal_wrap {
  @include flex-direction(column);
  height: 100%;
  padding: 30px 40px;
  max-width: 800;
  min-width: 650;
  min-height: 356;
  .footnote_wrap {
    @include justify-content(flex-end);
    font-size: 12px;
    color: #929292;
  }
  .description_wrap {
    margin: 15px 0 19px 0;
    padding: 12px 0 12px 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
    .description_title_wrap {
      @include flex-direction(row);
      @include align-items(center);
      margin-bottom: 9px;
      font-family: "NanumBarunGothic";
      font-size: 13px;
      font-weight: bold;
      color: #282828;
      img {
        margin-right: 6px;
        width: 12px;
        height: 20px;
      }
      .title_text {
        margin-top: 4px;
      }
    }
    .connect_description {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 1.5;
      color: #282828;
    }
    .connect_announcement {
      font-size: 12px;
      color: #929292;
    }
  }
  .oil_title {
    @include justify-content(flex-start);
    font-size: 14px;
    font-weight: bold;
    color: #282828;
  }
  .oil_cost_wrap {
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(flex-start);
    flex: 1;
    width: 100%;
    height: 100px;
    margin: 10px 0;
    padding: 18px 25px 18px 25px;
    border-radius: 5px;
    background-color: #f9f9f9;
    .oil_description_wrap {
      @include flex-direction(row);
      // @include justify-content(flex-start);
      @include align-items(space-between);
      height: 100%;
      width: 50%;
      .oil_type {
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(space-between);
        width: 170px;
        height: 100%;
        font-size: 14px;
        color: #929292;
      }
      .oil_value {
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(space-between);
        height: 100%;
        font-size: 14px;
        color: #282828;
      }
    }
    .oil_cost_content_wrap {
      @include flex-direction(column);
      @include justify-content(space-between);
      height: 100%;
      margin-left: 10px;
      padding-left: 20px;
      border-left: solid 1px #c5c0bc;
      .oil_cost_title {
        font-size: 14px;
        color: #929292;
      }
      .final_oil_cost_wrap {
        .positive_oil_cost {
          font-size: 18px;
          font-weight: bold;
          color: #007de8;
        }
        .negative_oil_cost {
          font-size: 18px;
          font-weight: bold;
          color: #e05656;
        }
        .oil_cost_text {
          margin-left: 10px;
          font-size: 12px;
          color: #282828;
        }
      }
    }
    .fail_content_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      width: 100%;
      
      font-size: 14px;
      color: #282828;
    }
  }
  .oil_cost_table_footnote {
    @include flex-direction(row);
    @include align-items(center);
    margin-bottom: 40px;
    img {
      width: 12px;
      height: 20px;
      margin-right: 6px;
    }
    .oil_cost_table_footnote_text {
      font-size: 12px;
      color: #282828;
      margin-top: 4px;
    }
  }
}

.advanced_common_modal_wrap {
  padding: 30px 40px;
  min-width: 700px;
  height: 56vh;
}

.setting_claim_percentage_modal_wrap {
  padding: 30px 40px;
  .setting_claim_percentage_select {
    margin-bottom: 20px;
  }
}

.notice_claim_pay {
  padding-bottom: 50px;
  text-align: left;
  p {
    margin: 5px 0;
    font-size: 13px;
    line-height: 18px;
  }
  .span_color_red {
    color: $red;
  }
  :last-child {
    margin: 25px 0 0 0;
    font-size: 12px;
  }
}
