@import '../../styles/mixin.scss';
@import '../../styles/variables.scss';


.common_header_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: $main-color;
  color: $white;
  z-index: 1200;
  .common_header_contents_area {
    @include align_items(center);
    width: 100%;
    height: 100%;
    .common_header_logo {
      @include align_items(center);
      height: 100%;
      margin-left: 20px;
    }
    .common_header_util {
      a {
        font-size: $normal-font-size;
        color:$white;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .apply_partner_button {
      @include flex_direction(row);
      margin-left: auto;
      margin-right: 20px;
      width: 110px;
      height: 28px;
      border-radius: 5px;
      background-color: $sub-color;
      padding: 6px 10px;
      cursor: pointer;
      img {
        width: 11px;
        height: 16px;
        margin-right: 10px;
      }
      div {
        font-size: 14px;
        color: $white;
      }
    }
    .none_apply_partner_button {
      margin-left: auto;
    }
  }

  .alert_icon {
    @include center_mid();
    position: relative;
    height: 100%;
  }
}

.center_mid {
  @include center_mid();
  @include flex_direction(row);
  text-align: center;
}

.notiTitleWrap {
  flex-direction: column;
  margin-left: 5px;
  width: 280px;
}
.notiTitle {
  font-size: 12px;
  color: #737373;
  padding: 2px;
}

.flexDirectionRow {
  @include flex_direction(row);
}

.noti_dropdown_item {
  @include align_items(center);
  cursor: pointer;
  border-bottom: 1px solid #d7d7d7;
  padding: 10px;

  .check {
  background-color: #f2f2f2;
  }

  .no_check {
  background-color: #ffe6c0;
  }

  .dropdown_item_badege {
    @include center_mid();
    @include flex_direction(row);
    text-align: center;
    font-size: 11px;
    color: #fff;
    margin-right: 5px;
    border-radius: 18px;
    width: 36px;
    height: 36px; 
  }

  .kakao_text {
    color : black;
  }
}



.topMenuWrap {
  @include justify_content(space-between);
  @include flex_direction(row);
  min-width: 1100px;
  background: $main-color;
  height: 100%;
  z-index: 100;
}

.topMenu {
  text-align: center;
  background-color: $main-color;
  height: 50px;
  margin: 0 auto;
  padding: 0 10px 0;
  width: 1080px;
}

.notificationItem{
  @include flex_direction(row);
}

.notification_date{
  font-size: 12px;
  color: #737373;
  @include justify_content(flex-end);
}

.rencar_logo {
  @include justify_content(center);
  @include flex_direction(column);
  z-index: 11000;
  // position: absolute;
  // left: 20px;
  // top: 0;
  margin-left:20px;
  height: 100%;
  cursor: pointer;
}

.menu_wrap {
  position: relative;
}

.right_menu {
  @include flex_direction(row);
  // width: 85px;
  height: 50px;
  background-color: $main-color;
  // position: absolute;
  // top: 0;
  // right: 20px;
  // display: inline-block;
}

.right_menu_button {
  height: 50px;
  color: $white;
  line-height: 50px;
  cursor: pointer;
  background-color: $main-color;
  text-align: end;
  font-size: 14px;
  font-weight: 700;
  color: $white;
  text-decoration: none;
  span {
    padding-left: 3px;
    font-weight: 300;
    font-size: 14px;
  }
}

.right_menu_border_button {
  color: $white;
  text-decoration: none;
  border: 1px solid $white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 10px;
}

.menuButton {
  display: inline-block;
  width: 100px;
  line-height: 50px;
  cursor: pointer;
  background-color: $main-color;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: $white;
}

.top_menu_dropdown_area {
  z-index: 100;
  width: 140px; 
  height: 100px; 
  position: absolute; 
  // top: 40px; 
  right: 18px; 
  background-color: $white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
  border-radius: 2px;
  .top_menu_dropdown_menu {
    position: relative;
    width: 100%;
    height: 100%;
    .top_menu_dropdown_list {
      padding: 25px 20px;
      .top_menu_dropdown_triangle {
        position: absolute;
        top: -7px;
        right: 20px;
        height: 3px;
        border-color: transparent;
        border-style: dashed dashed solid;
        border-width: 0 6px 7px;
        border-bottom-color: $white;
      }
      .top_menu_dropdown_list_item_row {
        font-size: 14px;
        height: 25px;
        @include align_items(center);
        cursor: pointer;
        color: $main-color;
      }
      .top_menu_dropdown_list_item_row:hover {
        .top_menu_dropdown_list_item_text {
          box-shadow: inset 0 -2px 0 #fff, inset 0 -10px 0 #ffe3b8;
        }
      }
      .top_menu_dropdown_list_item_text {
        width: auto;
      }
    }
  }
}

.noti_dropdown_area {
  width: 370px; 
  // height: 450px; 
  position: absolute; 
  top: 50px; 
  right: -20px;
  background-color: $white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
  border-radius: 2px;
  .noti_dropdown_menu {
    position: relative;
    width: 100%;
    height: 100%;
    .noti_dropdown_list {
      // padding: 25px 20px;
      .noti_dropdown_triangle {
        position: absolute;
        top: -7px;
        right: 20px;
        height: 3px;
        border-color: transparent;
        border-style: dashed dashed solid;
        border-width: 0 6px 7px;
        border-bottom-color: $white;
      }
      .noti_dropdown_list_item_row {
        @include align_items(center);
        font-size: 14px;
        height: 25px;
        cursor: pointer;
      }
      .noti_dropdown_list_item_row:hover {
        .top_menu_dropdown_list_item_text {
          box-shadow: inset 0 -2px 0 #fff, inset 0 -10px 0 #ffe3b8;
        }
      }
      .noti_dropdown_list_item_text {
        width: auto;
      }
    }
  }
}

.logout_btn {
  @include drag_disable();
  cursor: pointer;
}

.space_between {
  @include justify_content(space-between);
  padding-right: 15px;
}

.menu_rencar_notice, .menu_company_notice {
  flex-direction: row;
  padding: 0 10px;
  cursor: pointer;
  width: 450px;
  font-size: 14px;
}

.menu_rencar_notice:hover .set_hover_underline{
  text-decoration: underline;
  text-decoration-color: $white;
}
.menu_company_notice:hover .set_hover_underline{
  text-decoration: underline;
  text-decoration-color: $white;
}

@media (max-width: 1400px) {
  .menu_company_notice {
    display: none !important;
  }
}

@media (max-width: 860px) {
  .menu_rencar_notice {
    display: none !important;
  }
}

.set_hover_underline:hover{
  text-decoration: underline;
  text-decoration-color: $white;
}