@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.text_area_wrap {
  @include align_items(flex-start);
  width: 100%;
  height: 100%;
  padding: 5px 0;

  .textarea_btn {
    margin-left: auto !important;
    min-width: 50px;
    height: auto;
    word-break: break-all;
  }

  .text_area {
    position: relative;
    border: 1px solid $gray-4;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    margin-right: 10px;
    height: 120px;

    textarea {
      display: block;
      border: none;
      width: 100%;
      height: calc(100% - 20px);
      outline: none;
      line-height: 1.3;
      resize: none;
    }
    .byte_area {
      position: absolute;
      bottom: 5px;
      right: 10px;
      color: $gray-3;
      span {
        color: $black;
      }
    }
  }
}

.memo_text_area {
  width: 100%;
  height: 100%;
  padding: 5px 0;
  .textarea_btn {
    min-width: 50px;
    height: 28px;
    word-break: break-all;
    background: $gray-2;
    margin-left: 10px;
  }
  .text_area {
    position: relative;
    border: 1px solid $gray-4;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    margin-right: 10px;
    height: 150px;
    textarea {
      display: block;
      border: none;
      width: 100%;
      height: calc(100% - 20px);
      outline: none;
      line-height: 1.3;
      font-size: 14px;
    }
    .byte_area {
      // position: absolute;
      // bottom: 5px;
      // right: 10px;
      color: $gray-3;
      margin-left: auto;
      font-size: 12px;
      span {
        color: $black;
      }
    }
  }
}

.textarea_bottom {
  @include align_items(center);
  margin-top: 5px;
}
