@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.header_container {
  @include flex_direction(row);
  @include justify_content(space-between);
  margin-bottom: 10px;
  .header {
    @include align_items(center);
    @include flex_direction(row);
    font-size: 18px;
    font-weight: bold;
    color: $main-color;
    .car_name {
      margin-right: 15px;
    }
    .terminated {
      margin-left: 15px;
      padding: 6px 15px;
      @include align_items(center);
      border-radius: 3px;
      background-color: $gray-6;
      color: $gray-2;
      font-size: 14px;
      font-weight: normal;
    }
  }
}
.header_info {
  @include flex_direction(column);
  .header_table_wrap {
    @include flex_direction(row);
    @include justify_content(space-between);
    input,
    p,
    div {
      font-size: 12px !important;
    }
    textarea {
      height: 51px !important;
    }
  }

  .header_bottom_text {
    width: 100%;
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    color: $gray-3;
    margin: -40px 0 40px 0;
  }
}

.table_container {
  height: 650px;
  td {
    padding: 5px;
  }

  .table_header {
    @include flex_direction(row);
    margin-bottom: 15px;
  }
  .table_header_right {
    @include justify_content(flex-end);
    @include flex_direction(row);
    margin-bottom: 15px;
  }
  .desc {
    @include align_items(center);
    font-size: 12px;
    color: $gray-3;
  }
}
.table_row {
  @include flex_direction(row);
}

.overlay {
  @include center_mid();
  @include flex_direction(column);
  .overlay_title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    color: $gray-2;
    margin-bottom: 20px;
  }
  .overlay_content {
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: normal;
    color: $gray-2;
  }
}

.outline_btn {
  width: 50px;
  @include center_mid();
  cursor: pointer;
  height: 28px;
  border-radius: 3px;
  border: solid 1px $gray-2;
  color: $gray-2;
  font-size: 13px;
}

.fill_btn {
  width: 50px;
  @include center_mid();
  cursor: pointer;
  height: 28px;
  border-radius: 3px;
  border: solid 1px $gray-2;
  background-color: $gray-2;
  color: #fff;
  font-size: 13px;
}

.btn_area {
  @include align_items(center);
  margin-top: 50px;
}

.close_btn {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  padding: 10px 20px;
  border: 1px solid $gray-2;
  border-radius: 3px;
  color: $gray-2;
  font-size: 13px;
  background: white;
}

.deposit_done_text {
  font-size: 12px;
  color: $gray-3;
  margin-bottom: 10px;
}