@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.search_select_input_wrap {
  @include justify_content(flex-end);
  @include align_items(center);
  font-size: 14px;
  .search_input_select {
    width: 200px;
    margin: 0 10px;
    height: 36px;
    p {
      font-size: 14px;
    }
  }

  .search_input {
    width: 230px;
    height: 36px;
    font-size: 14px;
  }
}

.search_input_wrap {
  @include justify_content(flex-end);
  @include align_items(center);
  height: 36px;
  font-size: 14px;
  .search_input_select {
    width: 200px;
    margin: 0 10px;
    height: 100%;
  }
  .search_input_select > div:first-child {
    border: 1px solid $gray-4;
    height: 100%;
  }
  p {
    min-width: 50px;
  }
}

.selectWrap {
  @include flex_direction(row);
  height: 34px;
  .search_input_detail_select {
    width: 200px;
    height: 36px;
    margin: 0;
  }
  button {
    background: none;
    border: 1px solid $old-gray-5;
    width: 38px;
    height: 36px;
    margin: 0;
    background: url("/images/searchImages/search_black_icon.png") 50% 50% no-repeat;
    background-color: $old-gray-1;
    margin-left: auto;
    border-left: 1px solid $old-gray-5;
  }
}
