@import '../../../styles/variables.scss';
@import '../../../styles/mixin.scss';
.table{
  thead {
    th {
      font-size: 11px;
      font-weight: 700;
    }
  }
  tbody {
    th, td {
      font-size: 11px;
      font-weight: 400;
    }
  }
}

.requestTypeWrap{
  @include flex_direction(row);
  @include center_mid();  
}

.requestTypeColor {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.contract_component_title {
  width: auto;
  display: inline-block;
  font-size: 24px;
  margin: 10px 0;
  margin-top: 60px;
  font-weight: 300;
  float: left;
}

.contract_input_container {
  width: 100%;
  height: 100%;
  border-top: 2px solid #b8b8b8;
  border-bottom: 2px solid #b8b8b8;
  float: left;
}

.connect_rencar_call_modal_wrap {
  padding: 30px 40px;
  min-width: 975px;

  .search_input_wrap {
    @include justify-content(flex-end);
  }
  .table_area {
    margin-top: 21px;
    margin-bottom: 50px;
    height: 400px;
    overflow-y: auto;
  }
}

.checkbox_div {
  border-radius: 2px;
  width: 18px;
  height: 18px;
  margin: auto;
  padding-top: 4px;
}

.active_checkbox {
  background-color: $sub-color;
  border: none;
}

.inactive_checkbox {
  border: solid 1px $gray-3;
  background-color: $white;
}

.button_area {
  @include flex_direction(row);
  @include justify_content(flex-end);
  margin-top: 20px;
  position: absolute;
  bottom: 30px;
  right: 40px;
  .cancel_button {
    @include center_mid();
    width: 74px;
    height: 42px;
    border-radius: 3px;
    border: solid 1px $gray-2;
    margin-right: 15px;
    cursor: pointer;
  }
  .complete_button {
    @include center_mid();
    width: 108px;
    height: 42px;
    border-radius: 3px;
    background-color: $gray-2;
    color: $white;
    cursor: pointer;
  }
}